import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendTestEmail } from '../../../../apis/campaigns.ts';
import { useToast } from '../../../../context/ToastContext';

function TestMailModalComponent({ visible, setVisible, smtp }) {
  const toast = useToast();
  const schema = yup.object().shape({
    email: yup.string().email('Email must be a valid email').required('Email is required'),
  });

  const defaultValues = {
    first_name: '',
    last_name: '',
    email: '',
    position: '',
    smtp: null,
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  async function onSubmit(data) {
    setValue('smtp', smtp);
    const sanitizedSendData = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );
    delete sanitizedSendData.smtp.interface_type;
    let response = {};
    response = await sendTestEmail(sanitizedSendData);
    if (response.response?.data.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.response.data.message, life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'success', summary: 'Success', detail: 'Email sent', life: 3000,
      });
    }
  }

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold">
        Send a test email for the campaign
      </span>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      modal
      draggable={false}
      header={headerElement}
      onHide={() => setVisible(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left ml-3" style={{ marginBottom: '-2rem' }}>
        <Controller
          name="first_name"
          control={control}
          render={({ field, fieldState }) => (
            <div>
              <InputText
                id={field.name}
                placeholder="First name"
                value={field.value}
                className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                onChange={(e) => { field.onChange(e.target.value); trigger('first_name'); }}
                aria-describedby={`${field.name}-info`}
              />
              <div>
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />
        <Controller
          name="last_name"
          control={control}
          render={({ field, fieldState }) => (
            <div>
              <InputText
                id={field.name}
                placeholder="Last name"
                value={field.value}
                className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                onChange={(e) => { field.onChange(e.target.value); trigger('last_name'); }}
                aria-describedby={`${field.name}-info`}
              />
              <div>
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <div>
              <InputText
                id={field.name}
                placeholder="Email*"
                value={field.value}
                className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                onChange={(e) => { field.onChange(e.target.value); trigger('email'); }}
                aria-describedby={`${field.name}-info`}
              />
              <div>
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />
        <Controller
          name="position"
          control={control}
          render={({ field, fieldState }) => (
            <div>
              <InputText
                id={field.name}
                placeholder="Position"
                value={field.value}
                className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                onChange={(e) => { field.onChange(e.target.value); trigger('position'); }}
                aria-describedby={`${field.name}-info`}
              />
            </div>
          )}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem' }}>
          <Button disabled={!isValid} label="Send" type="submit" icon="pi pi-send" />
        </div>
      </form>
    </Dialog>
  );
}

TestMailModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  smtp: PropTypes.object,
};

TestMailModalComponent.defaultProps = {
  smtp: null,
};

export default TestMailModalComponent;
