import axiosHandler from './index';

const getLandingPages = (detailed) => {
    return axiosHandler.get('/pages/', { params: { detailed: detailed } });
}

const getLandingPage = (id) => {
    return axiosHandler.get(`/pages/${id}`)
}

const createLandingPage = (data) => {
    return axiosHandler.post('/pages/', data);
}

const editLandingPage = (data) => {
    return axiosHandler.put(`/pages/${data.id}`, data);
}

const importLandingPage = (data) => {
    return axiosHandler.post('/import/site', data)
}

const deleteLandingPage = (id) => {
    return axiosHandler.delete(`/pages/${id}`)
}

export { getLandingPages, getLandingPage, createLandingPage, editLandingPage, importLandingPage, deleteLandingPage };
