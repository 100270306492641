import axiosHandler from './index';

const getMedias = () => {
    return axiosHandler.get('/resources/');
}

const createMedia = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return axiosHandler.post('/resources/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

const editMedia = (data) => {
    return axiosHandler.put(`/resources/${data.id}`, data);
}

const deleteMedia = (id) => {
    return axiosHandler.delete(`/resources/${id}`)
}

const deleteMedias = (ids) => {
    return axiosHandler.delete(`/resources/`, { data: { ids } })
}

const getAllowedMediaTypes = () => {
    return axiosHandler.get('/resources/types');
}

export { getMedias, createMedia, editMedia, deleteMedia, deleteMedias, getAllowedMediaTypes };
