import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { Calendar } from 'primereact/calendar';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import ManageLandingPagesModalComponent from '../ManageLandingPagesModalComponent/ManageLandingPagesModalComponent';
import { deleteLandingPage } from '../../../apis/landingPages.ts';
import {
  formatDate, ownerInfo, RelationshipType, landingPagesTypes, behaviorsReasonsBuilderList,
} from '../../utils';
import { useToast } from '../../../context/ToastContext';
import getUserInfoState from '../../../redux/reducers/selectors/userInfo';

const typeFilterTemplate = (options) => {
  const typeOptions = Object.values(landingPagesTypes).map((type) => ({
    label: type.name,
    value: type.name,
  }));
  return (
    <Dropdown
      value={options.value}
      options={typeOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Select a Type"
      className="p-column-filter"
      showClear
    />
  );
};

const typeBodyTemplate = (rowData) => {
  let type = rowData.type ? rowData.type : 'credentials';
  type = landingPagesTypes.find((op) => op.value === type);
  return (
    <Tag
      value={type?.name}
      style={{ backgroundColor: type.color }}
      className="mt-3 mb-3 ml-auto"
    />
  );
};

async function acceptDeleteLandingPage(landingPageId, toast) {
  const response = await deleteLandingPage(landingPageId);
  if (response.success) {
    toast.current.show({
      severity: 'success', summary: 'Success', detail: response.message, life: 3000,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } else {
    toast.current.show({
      severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? `There was a problem trying to remove the landing page: ${response.response.data.message}` : 'An unexpected error occurred deleting the landing page', life: 3000,
    });
  }
}

const deleteLandingPageConfirmation = (landingPage, toast) => {
  confirmDialog({
    message: 'Do you want to delete this landing page?',
    header: `Delete  ${landingPage.name} Confirmation`,
    headerStyle: { wordBreak: 'break-all' },
    icon: 'pi pi-info-circle',
    defaultFocus: 'reject',
    acceptClassName: 'p-button-danger',
    accept: () => acceptDeleteLandingPage(landingPage.id, toast),
  });
};

function LandingPagesListComponent({ landingPages }) {
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState();
  const [selectedLandingPages, setSelectedLandingPages] = useState([]);
  const toast = useToast();
  const { id } = useSelector(getUserInfoState);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      ],
    },
    modified_date: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
    type: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.EQUALS },
      ],
    },
  });

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Landing Pages</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );
  const header = renderHeader();
  const dateBodyTemplate = (rowData) => formatDate(rowData.modified_date);
  const dateFilterTemplate = (options) => <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
  const actions = () => (
    [
      {
        label: 'Make a copy',
        icon: 'pi pi-copy',
        command: () => { setMode('copy'); setVisible(true); },
      },
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => { setMode('edit'); setVisible(true); },
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => { deleteLandingPageConfirmation(selectedLandingPages, toast); },
      },
    ]);

  const actionsCustomer = () => (
    [
      {
        label: 'Make a copy',
        icon: 'pi pi-copy',
        command: () => { setMode('copy'); setVisible(true); },
      },
    ]);

  const actionBodyTemplate = (value) => {
    const settingsRef = useRef(null);
    const getMenuModel = () => (
      value.belongs_to === RelationshipType.ANCESTOR ? actionsCustomer() : actions()
    );

    return (
      <div>
        <Button
          type="button"
          icon="pi pi-cog"
          className="justify-content-center align-items-center h-3rem w-3rem border-circle"
          style={{ marginLeft: '-0.5rem' }}
          onClick={(e) => {
            setSelectedLandingPages(value);
            settingsRef.current.toggle(e);
          }}
        />
        <TieredMenu model={getMenuModel()} popup ref={settingsRef} style={{ width: 'auto' }} />
      </div>
    );
  };

  const processedPages = landingPages?.map((item) => ({
    ...item,
    modified_date: new Date(item.modified_date),
  }));

  return (
    landingPages?.length > 0 && (
      <>
        <ConfirmDialog />
        <ManageLandingPagesModalComponent
          visible={visible}
          setVisible={setVisible}
          landingPageId={selectedLandingPages.id}
          mode={mode}
        />
        <DataTable
          size="small"
          value={processedPages}
          paginator
          header={header}
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          selection={selectedLandingPages}
          onSelectionChange={(e) => selectedLandingPages(e.value)}
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={['name', 'modified_date']}
          emptyMessage="No landing pages found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} landing pages"
        >
          <Column header="Owner" style={{ minWidth: '3rem' }} body={(e) => ownerInfo(e, id)} />
          <Column field="type" header="Type" sortable style={{ minWidth: '4rem' }} body={typeBodyTemplate} filter filterElement={typeFilterTemplate} />
          <Column field="name" header="Name" sortable filter filterPlaceholder="Search by name" style={{ minWidth: '18rem', maxWidth: '46rem', wordBreak: 'break-word' }} />
          <Column header="Behaviors & Reasons" style={{ maxWidth: '5rem' }} body={(e) => behaviorsReasonsBuilderList(e?.behaviors_reasons)} />
          <Column field="modified_date" header="Last modification" sortable dataType="date" style={{ minWidth: '12rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={(e) => actionBodyTemplate(e)} />
        </DataTable>
      </>
    )
  );
}

LandingPagesListComponent.propTypes = {
  landingPages: PropTypes.array,
};

LandingPagesListComponent.defaultProps = {
  landingPages: [],
};

export default LandingPagesListComponent;
