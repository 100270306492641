import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { TieredMenu } from 'primereact/tieredmenu';
import { useSelector } from 'react-redux';
import ManageGroupModalComponent from '../ManageGroupModalComponent/ManageGroupModalComponent';
import { formatDate, ownerInfo, RelationshipType } from '../../utils';
import { deleteGroup } from '../../../apis/usersAndGroups.ts';
import { useToast } from '../../../context/ToastContext';
import getUserInfoState from '../../../redux/reducers/selectors/userInfo';
import './GroupsListComponent.scss';

const rowClassName = (group) => (group.processing ? 'disabled-row' : null);

async function acceptDeleteGroupConfirmation(groupId, toast) {
  const response = await deleteGroup(groupId);
  if (response.success) {
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: response.message,
      life: 3000,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } else {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: response?.response?.data?.message
        ? `There was a problem trying to remove the group: ${response.response.data.message}`
        : 'An unexpected error occurred deleting the group',
      life: 3000,
    });
  }
}

const deleteGroupConfirmation = (group, toast) => {
  confirmDialog({
    message: 'Do you want to delete this group?',
    header: `Delete ${group.name} group`,
    headerStyle: { wordBreak: 'break-all' },
    icon: 'pi pi-info-circle',
    defaultFocus: 'reject',
    acceptClassName: 'p-button-danger',
    accept: () => acceptDeleteGroupConfirmation(group.id, toast),
  });
};

function GroupsListComponent({ groups }) {
  const [visible, setVisible] = useState(false);
  const [group, setGroup] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const toast = useToast();
  const { id } = useSelector(getUserInfoState);

  const settingsRef = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    updated_at: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    num_targets: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Groups</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );

  const actions = () => [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        setVisible(true);
      },
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        deleteGroupConfirmation(group, toast);
      },
    },
  ];

  const actionBodyTemplate = (value) => (
    !value.processing ? (
      value.belongs_to === RelationshipType.ANCESTOR ? (
        <Button type="button" icon="pi pi-cog" className="justify-content-center align-items-center h-3rem w-3rem border-circle" style={{ marginLeft: '-0.5rem' }} disabled />
      ) : (
        <Button
          type="button"
          icon="pi pi-cog"
          className="justify-content-center align-items-center h-3rem w-3rem border-circle"
          style={{ marginLeft: '-0.5rem' }}
          onClick={(e) => {
            settingsRef.current.toggle(e);
            setGroup(value);
          }}
        >
          <TieredMenu model={actions()} popup ref={settingsRef} style={{ width: 'auto' }} />
        </Button>
      )
    ) : (
      <ProgressSpinner className="progress-spinner" style={{ width: '50px', height: '50px', marginLeft: '-0.5rem' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
    )
  );

  const header = renderHeader();
  const dateBodyTemplate = (rowData) => formatDate(rowData.updated_at);
  const dateFilterTemplate = (options) => (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="mm/dd/yy"
      placeholder="mm/dd/yyyy"
      mask="99/99/9999"
    />
  );

  const processedGroups = groups?.map((item) => ({
    ...item,
    modified_date: new Date(item.modified_date),
  }));

  return (
    groups?.length > 0 && (
      <>
        <ConfirmDialog />
        <ManageGroupModalComponent visible={visible} setVisible={setVisible} groupId={group?.id} />
        <DataTable
          size="small"
          value={processedGroups}
          paginator
          header={header}
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          selection={selectedGroups}
          onSelectionChange={(e) => setSelectedGroups(e.value)}
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={['name', 'num_targets', 'updated_at']}
          emptyMessage="No groups found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} groups"
          rowClassName={rowClassName}
        >
          <Column header="Owner" style={{ minWidth: '3rem' }} body={(e) => ownerInfo(e, id)} />
          <Column field="name" header="Name" sortable filter filterPlaceholder="Search by name" style={{ minWidth: '14rem', maxWidth: '46rem', wordBreak: 'break-word' }} />
          <Column field="num_targets" header="Members" sortable filter filterPlaceholder="Search by number of members" style={{ minWidth: '14rem' }} />
          <Column field="updated_at" header="Last modification" sortable dataType="date" style={{ minWidth: '12rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
          {/* <Column field="company" header="Anotations" /> */}
          <Column body={(e) => actionBodyTemplate(e)} />
        </DataTable>
      </>
    )
  );
}

GroupsListComponent.propTypes = {
  groups: PropTypes.array,
};

GroupsListComponent.defaultProps = {
  groups: [],
};

export default GroupsListComponent;
