import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import { InputSwitch } from 'primereact/inputswitch';
import PropTypes from 'prop-types';
import withContent from '../withContent';
import { getDetailedCampaigns } from '../../apis/campaigns.ts';
import RecentCampaignsComponent from '../../components/Dashboard/RecentCampaignsComponent/RecentCampaignsComponent';
import DoughnutComponent from '../../components/Dashboard/StatsComponents/DoughnutComponent';
import SuccessTimelineComponent from '../../components/Dashboard/StatsComponents/SuccessTimelineComponent';
import SMSDoughnutComponent from '../../components/Dashboard/StatsComponents/SMSDoughnutComponent';
import CustomSpinnerComponent from '../../components/CustomSpinnerComponent/CustomSpinnerComponent';
import { useToast } from '../../context/ToastContext';
import InfoComponent from '../../components/UtilsComponents/InfoComponent';

function classifiedCampaigns(campaigns) {
  return campaigns?.reduce((acc, campaign) => {
    const { type } = campaign;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(campaign);
    acc[type].sort((a, b) => a.id - b.id);
    return acc;
  }, {});
}

function AggregateTestCampaignsToggle({
  disabled, showTestStat, setShowTestStats, setLoading,
}) {
  return (
    <label className="ml-2" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <span className="ml-2">
        Include tests&nbsp;
      </span>
      <InputSwitch
        checked={showTestStat}
        onChange={(e) => { setShowTestStats(e.value); setLoading(true); }}
        disabled={disabled}
      />
    </label>
  );
}
AggregateTestCampaignsToggle.propTypes = {
  disabled: PropTypes.bool.isRequired,
  showTestStat: PropTypes.bool.isRequired,
  setShowTestStats: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

function DashboardPage() {
  const [campaigns, setCampaigns] = useState();
  const [grouppedCampaigns, setGrouppedCampaigns] = useState([]);
  const [grouppedCampaignsWithTest, setGrouppedCampaignsWithTest] = useState([]);
  const [error, setError] = useState(false);
  const [showTestStat, setShowTestStats] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let response;
      if (showTestStat) {
        if (grouppedCampaignsWithTest?.length === 0 && grouppedCampaigns?.length === 0) {
          // Case no campaign retrieved yet
          response = await getDetailedCampaigns();
        }
        if (grouppedCampaignsWithTest?.length === 0) {
          // Case add test campaigns
          response = await getDetailedCampaigns(true);
        }
      } else if (grouppedCampaigns?.length === 0) {
        // Case only no test campaigns
        response = await getDetailedCampaigns(false);
      }
      if (response && !Array.isArray(response?.campaigns) && response?.campaigns !== null) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? response?.response?.data?.message : 'An unexpected error occurred getting campaings', life: 3000,
        });
        setError(true);
      }
      if (showTestStat) {
        if (grouppedCampaignsWithTest?.length === 0 && grouppedCampaigns?.length === 0) {
          setGrouppedCampaignsWithTest(classifiedCampaigns(response.campaigns));
        }
        if (grouppedCampaignsWithTest?.length === 0) {
          setGrouppedCampaignsWithTest(
            classifiedCampaigns(
              [...grouppedCampaigns?.Email || [],
                ...grouppedCampaigns?.SMS || [],
                ...response.campaigns || []],
            ),
          );
        }
      } else if (grouppedCampaigns?.length === 0) {
        setGrouppedCampaigns(classifiedCampaigns(response.campaigns));
      }
      // eslint-disable-next-line no-unused-expressions
      response && setCampaigns(response);
      setLoading(false);
    }
    fetchData();
  }, [showTestStat]);

  if (campaigns === undefined || loading) {
    return (
      <>
        <AggregateTestCampaignsToggle
          disabled
          showTestStat={showTestStat}
          setShowTestStats={setShowTestStats}
          setLoading={setLoading}
        />
        <CustomSpinnerComponent text="campaigns" />
      </>
    );
  }

  const totalCampaigns = (grouppedCampaignsWithTest?.Email?.length || 0)
  + (grouppedCampaignsWithTest?.SMS?.length || 0)
  + (grouppedCampaigns?.Email?.length || 0)
  + (grouppedCampaigns?.SMS?.length || 0);

  return (
    <>
      {error && (
        <InfoComponent title="An error occurred" message="Please try refreshing the page. If the error persists, please contact us" severity="error" />
      )}
      <AggregateTestCampaignsToggle
        disabled={false}
        showTestStat={showTestStat}
        setShowTestStats={setShowTestStats}
        setLoading={setLoading}
      />
      {totalCampaigns > 0 && (
        <>
          <h4 className="ml-2 text-left" style={{ color: '#555', marginLeft: '10px' }}>EMAIL PHISHING CAMPAIGNS</h4>
          <DoughnutComponent
            campaigns={showTestStat
              ? grouppedCampaignsWithTest?.Email || []
              : grouppedCampaigns?.Email || []}
          />
          <SuccessTimelineComponent
            title="Email Phishing Success Overview"
            campaigns={showTestStat
              ? grouppedCampaignsWithTest?.Email || []
              : grouppedCampaigns?.Email || []}
          />
          <Divider />
          <h4 className="ml-2 text-left" style={{ color: '#555', marginLeft: '10px' }}>SMS PHISHING CAMPAIGNS</h4>
          <SMSDoughnutComponent
            campaigns={showTestStat ? grouppedCampaignsWithTest.SMS : grouppedCampaigns.SMS}
          />
          <SuccessTimelineComponent
            title="Smishing Success Overview"
            campaigns={showTestStat ? grouppedCampaignsWithTest.SMS : grouppedCampaigns.SMS}
          />
          <h2 className="text-left ml-2">Recent Campaigns</h2>
          <Button className="flex m-2" onClick={() => navigate('/campaigns')}>View All</Button>
          <RecentCampaignsComponent
            campaigns={showTestStat
              ? [...grouppedCampaignsWithTest?.Email || [], ...grouppedCampaignsWithTest?.SMS || []]
              : [...grouppedCampaigns?.Email || [], ...grouppedCampaigns?.SMS || []]}
          />
        </>
      )}
      {totalCampaigns === 0 && !error && (
        <InfoComponent
          title="No campaign"
          message="No campaign created yet. Let&apos;s create one!"
          severity="info"
        />
      )}
    </>
  );
}

export default withContent(DashboardPage);
