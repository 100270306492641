import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import ManageSendingProfilesFormComponent from '../ManageSendingProfilesFormComponent/ManageSendingProfilesFormComponent';
import { getSmtpGateway, getSmsGateway } from '../../../apis/sendingProfiles.ts';
import CustomSpinnerComponent from '../../CustomSpinnerComponent/CustomSpinnerComponent';
import { useToast } from '../../../context/ToastContext';

function ManageSendingProfilesModalComponent({
  visible, setVisible, sendingProfileId, sendingProfileInterfaceType, mode,
}) {
  const [sendingProfile, setSendingProfile] = useState(null);
  const toast = useToast();
  useEffect(() => {
    let response;
    async function fetchData() {
      if (sendingProfileId && visible) {
        switch (sendingProfileInterfaceType) {
          case 'SMTP':
            response = await getSmtpGateway(sendingProfileId);
            if (response.response?.data.success === false || response.isAxiosError) {
              setVisible(false);
              toast.current.show({
                severity: 'error', summary: 'Error', detail: response.response.data.message || 'An unexpected error occurred getting the SMTP information', life: 3000,
              });
              setSendingProfile(null);
            } else {
              setSendingProfile(response);
            }
            break;
          case 'SMS Gateway':
            response = await getSmsGateway(sendingProfileId);
            if (response.response?.data.success === false || response.isAxiosError) {
              setVisible(false);
              toast.current.show({
                severity: 'error', summary: 'Error', detail: response.response.data.message || 'An unexpected error occurred getting the SMS Gateway information', life: 3000,
              });
              setSendingProfile(null);
            } else {
              setSendingProfile(response);
            }
            break;
          default:
        }
      }
    }
    fetchData();
  }, [sendingProfileId, visible]);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold">
        {mode === 'edit' ? `Edit ${sendingProfile?.name ? sendingProfile.name : ''} ` : 'Create a new'}
        {' '}
        sending profile
      </span>
    </div>
  );

  const hideModal = () => {
    setSendingProfile(null);
    setVisible(false);
  };

  return (
    <Dialog visible={visible} modal draggable={false} header={headerElement} style={{ width: '70rem' }} onHide={() => hideModal()}>
      {sendingProfile === null && sendingProfileId ? <CustomSpinnerComponent text="sending profile" />
        : (
          <ManageSendingProfilesFormComponent
            sendingProfile={sendingProfile}
            mode={mode}
            setVisible={setVisible}
          />
        )}
    </Dialog>
  );
}

ManageSendingProfilesModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  sendingProfileId: PropTypes.number,
  sendingProfileInterfaceType: PropTypes.string,
  mode: PropTypes.string,
};

ManageSendingProfilesModalComponent.defaultProps = {
  sendingProfileId: null,
  sendingProfileInterfaceType: '',
  mode: null,
};

export default ManageSendingProfilesModalComponent;
