/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import { Divider } from 'primereact/divider';
import PropTypes from 'prop-types';
import { ListBox } from 'primereact/listbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tag } from 'primereact/tag';
import {
  behaviorsReasonsBuilderList,
  behaviorsReasonsBuilderType,
  behaviorsReasonsExtraction,
  landingPagesTypes,
} from '../../../utils';
import { templateTypes, difficultyOptions } from '../../utils';
import './CampaignResumeComponent.scss';

function RenderIsolatedContent({ htmlContent }) {
  const iframeRef = useRef(null);
  useEffect(() => {
    const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(htmlContent);
    iframeDoc.close();
  }, [htmlContent]);
  return (
    <iframe
      ref={iframeRef}
      style={{
        width: '100%', height: '500px', overflowY: 'auto', border: 'none',
      }}
      title="Content"
    />
  );
}

RenderIsolatedContent.propTypes = {
  htmlContent: PropTypes.string.isRequired,
};

function CampaignModeType({ type }) {
  let mode = type || 'credentials';
  mode = landingPagesTypes.find((op) => op.value === mode);
  return (
    <Tag
      value={mode?.description}
      style={{ backgroundColor: mode.color }}
      icon={mode?.icon}
      className="mt-3 mb-3"
    />
  );
}

CampaignModeType.propTypes = {
  type: PropTypes.string.isRequired,
};

function calculateTotalTargets(groups) {
  const total = groups.map((group) => group.num_targets).reduce((acc, current) => acc + current, 0);
  return total;
}

const formatDateTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleString();
};

function GroupListItem({ option }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <div>{option.name}</div>
        <small>
          {option.num_targets}
          {' '}
          targets
        </small>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <small style={{ marginRight: '4px', marginBottom: '0.5rem' }}>
          {formatDateTime(option.updated_at)}
        </small>
        <Tag severity="danger">{option.shared ? 'Shared' : 'Private'}</Tag>
      </div>
    </div>
  );
}

GroupListItem.propTypes = {
  option: PropTypes.object,
};

GroupListItem.defaultProps = {
  option: null,
};

function CampaignResumeComponent({ campaignInfo }) {
  const templateType = campaignInfo.sendingProfile?.interface_type === 'SMS Gateway' ? 'SMS' : 'Email';
  const campaignTypeName = campaignInfo.sendingProfile?.interface_type === 'SMS Gateway' ? 'Smishing Campaing' : 'Email Phishing Campaign';
  const campaignModeType = !campaignInfo.credentialsPage ? 'learning' : 'credentials';
  const difficulty = difficultyOptions.find((op) => op.value === campaignInfo?.difficulty);

  return (
    <div className="campaign-resume">
      <h1 style={{ marginTop: '-20px' }}>{campaignInfo.name}</h1>
      <div className="tag-container">
        {campaignInfo.test_type && (
          <Tag
            value="Test"
            severity="success"
            className="mt-3 mb-3 mr-3"
          />
        )}
        {campaignInfo.sendingProfile && (
          <Tag
            value={campaignTypeName}
            icon={templateTypes[templateType].icon}
            severity={templateTypes[templateType].color}
            className="mt-3 mb-3 mr-3"
          />
        )}
        {
          (campaignInfo.learningPage || campaignInfo.credentialsPage)
          && <CampaignModeType type={campaignModeType} />
        }
        {difficulty && (
          <Tag
            value={difficulty?.name}
            style={{ backgroundColor: difficulty.color }}
            className="mt-3 mb-3 ml-auto"
          />
        )}
      </div>
      <Accordion>
        <AccordionTab header={`Template: ${campaignInfo.template?.name || 'No template'}`}>
          {campaignInfo.template ? (
            <>
              <label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Configuration</label>
              <ul className="inline-list">
                <li key="shared-template" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Shared template:</label>
                  <span>{campaignInfo.template.shared ? 'Yes' : 'No'}</span>
                </li>
                <li key="redirect" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Behaviors & Reasons:</label>
                  {campaignInfo?.template?.behaviors_reasons ? behaviorsReasonsBuilderList(campaignInfo.template.behaviors_reasons) : 'None'}
                </li>
                {templateType === 'SMS' ? (
                  <li key="sender_id" style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Sender ID:</label>
                    <span>{campaignInfo.template.sms_sender_id}</span>
                  </li>
                ) : (
                  <>
                    <li key="envelope_sender" style={{ display: 'flex', alignItems: 'center' }}>
                      <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Envelope sender:</label>
                      <span>{campaignInfo.template.envelope_sender}</span>
                    </li>
                    <li key="subject" style={{ display: 'flex', alignItems: 'center' }}>
                      <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Email subject:</label>
                      <span>{campaignInfo.template.subject}</span>
                    </li>
                    <li key="attachments">
                      <label style={{ fontWeight: 'bold' }}>Attachments:</label>
                      {' '}
                      {campaignInfo.template.attachments?.length > 0 ? (
                        <ul>
                          {campaignInfo.template.attachments.map((attachment) => (
                            <li key={`${attachment.name}-${attachment.type}`}>
                              {attachment.name}
                              {' '}
                              (
                              {attachment.type}
                              )
                            </li>
                          ))}
                        </ul>
                      ) : 'No attachments'}
                    </li>
                  </>
                )}
                <li key="campaign_url" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Redirection to URL:</label>
                  <a href={`${campaignInfo.url}${campaignInfo.credentialsPage ? '/login' : ''}`} target="_blank" rel="noopener noreferrer">
                    {`${campaignInfo.url}${campaignInfo.credentialsPage ? '/login' : ''}`}
                  </a>
                </li>
              </ul>
              {templateType === 'Email' ? (
                <TabView style={{ padding: 0 }}>
                  <TabPanel header="HTML">
                    <RenderIsolatedContent htmlContent={campaignInfo.template.html} />
                  </TabPanel>
                  <TabPanel header="Text">
                    <div
                      style={{ maxHeight: '500px', overflowY: 'auto' }}
                    >
                      {campaignInfo.template.text}
                    </div>
                  </TabPanel>
                </TabView>
              ) : (
                <>
                  <Divider align="center">
                    <span className="p-tag">Text</span>
                  </Divider>
                  <div
                    style={{ maxHeight: '500px', overflowY: 'auto' }}
                  >
                    {campaignInfo.template.text.replace(/{{\.URL}}/g, campaignInfo.url)}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <label style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Information not available
              </label>
              Template could have been removed
            </>
          )}
        </AccordionTab>
        <AccordionTab header={`Credentials Page: ${campaignInfo?.credentialsPage?.name || 'No page'}`}>
          {campaignInfo.credentialsPage ? (
            <>
              <label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Configuration</label>
              <ul>
                <li key="shared-page" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Shared credentials page:</label>
                  <span>{campaignInfo.credentialsPage.shared ? 'Yes' : 'No'}</span>
                </li>
                <li key="reasons-and-behaviors" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Behaviors & Reasons:</label>
                  {campaignInfo?.credentialsPage?.behaviors_reasons ? behaviorsReasonsBuilderList(campaignInfo.credentialsPage.behaviors_reasons) : 'None'}
                </li>
                <li key="credentials" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Capture credentials:</label>
                  <span>{campaignInfo.credentialsPage.capture_credentials ? 'Yes' : 'No'}</span>
                </li>
                <li key="password" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Capture password:</label>
                  <span>{campaignInfo.credentialsPage.capture_passwords ? 'Yes' : 'No'}</span>
                </li>
                {campaignInfo.credentialsPage.capture_passwords && (
                  <li key="redirect" style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>On credentials submission, redirect to:</label>
                    <a href={campaignInfo.credentialsPage.redirect_url} target="_blank" rel="noopener noreferrer">{campaignInfo.credentialsPage.redirect_url.startsWith('{{.') ? campaignInfo.url : campaignInfo.credentialsPage.redirect_url}</a>
                  </li>
                )}
              </ul>
              This preview is an approximation of the real one;
              {' '}
              some styles and elements could be omitted.
              <Divider align="center">
                <span className="p-tag">HTML</span>
              </Divider>
              <RenderIsolatedContent htmlContent={campaignInfo.credentialsPage.html} />
            </>
          ) : (
            (campaignInfo.credentialsPage === undefined || campaignInfo.credentialsPage === 0)
              ? 'Credentials page is not set for this campaign'
              : (
                <>
                  <label style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                    Information not available
                  </label>
                  Landing page could have been removed
                </>
              )
          )}
        </AccordionTab>
        <AccordionTab header={`Learning Page: ${campaignInfo.learningPage?.name || 'No page'}`}>
          {campaignInfo.learningPage ? (
            <>
              <label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Configuration</label>
              <ul>
                <li key="shared-page" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Shared learning page:</label>
                  <span>{campaignInfo.learningPage.shared ? 'Yes' : 'No'}</span>
                </li>
                <li key="reasons-and-behaviors" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Behaviors & Reasons:</label>
                  {campaignInfo?.learningPage?.behaviors_reasons ? behaviorsReasonsBuilderList(campaignInfo.learningPage.behaviors_reasons) : 'None'}
                </li>
              </ul>
              This preview is an approximation of the real one;
              {' '}
              some styles and elements could be omitted.
              <Divider align="center">
                <span className="p-tag">HTML</span>
              </Divider>
              <RenderIsolatedContent htmlContent={campaignInfo.learningPage.html} />
            </>
          ) : (
            <>
              <label style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Information not available
              </label>
              Learning page could have been removed
            </>
          )}
        </AccordionTab>
        <AccordionTab header="Behaviors & Reasons">
          <label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Reasons</label>
          <ul>
            {behaviorsReasonsExtraction(campaignInfo.behaviors_reasons)?.filter((t) => t.type !== 'C').length === 0 && 'None'}
            {behaviorsReasonsExtraction(campaignInfo.behaviors_reasons)?.map((t) => {
              if (t.type !== 'C') {
                return (
                  <li key={t.id} style={{ marginBottom: '5px' }}>
                    <span style={{ fontWeight: 'bold' }}>{t.externalId}</span>
                    {`: ${t.name} `}
                    {behaviorsReasonsBuilderType(t.type)}
                  </li>
                );
              }
            })}
          </ul>
          <label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Behaviors</label>
          <ul>
            {behaviorsReasonsExtraction(campaignInfo.behaviors_reasons)?.filter((t) => t.type === 'C').length === 0 && 'None'}
            {behaviorsReasonsExtraction(campaignInfo.behaviors_reasons)?.map((t) => {
              if (t.type === 'C') {
                return (
                  <li key={t.id} style={{ marginBottom: '5px' }}>
                    <span style={{ fontWeight: 'bold' }}>{t.externalId}</span>
                    {`: ${t.name} `}
                    {behaviorsReasonsBuilderType(t.type)}
                  </li>
                );
              }
            })}
          </ul>
        </AccordionTab>
        <AccordionTab header={campaignInfo.sendingProfile ? (`${templateType === 'SMS' ? 'SMS Gateway' : 'SMTP'} Profile: ${campaignInfo.sendingProfile.name}`) : 'No sending profile'}>
          {campaignInfo.sendingProfile ? (
            <>
              <label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Configuration</label>
              <ul>
                {templateType === 'SMS' && (
                  <li key="type" style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Type:</label>
                    <span>{campaignInfo.sendingProfile.type}</span>
                  </li>
                )}
                <li key="shared-sending-profile" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Shared sending profile:</label>
                  <span>{campaignInfo.sendingProfile.shared ? 'Yes' : 'No'}</span>
                </li>
              </ul>
            </>
          ) : (
            <>
              <label style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Information not available
              </label>
              Sending profile could have been removed
            </>
          )}
        </AccordionTab>
        <AccordionTab header="Campaign Period">
          <>
            <div className="p-field">
              <label>Launch Date</label>
              <p>{campaignInfo.launch_date ? new Date(campaignInfo.launch_date).toLocaleString() : 'Now'}</p>
            </div>
            <Divider />
            <div className="p-field">
              <label>End Date</label>
              <p>{campaignInfo.send_by_date && campaignInfo.send_by_date !== '0001-01-01T00:00:00Z' ? new Date(campaignInfo.send_by_date).toLocaleString() : 'Not set'}</p>
            </div>
            {campaignInfo.completed_date && campaignInfo.completed_date !== '0001-01-01T00:00:00Z' && (
              <>
                <Divider />
                <div className="p-field">
                  <label>Completed Date</label>
                  <p>{new Date(campaignInfo.completed_date).toLocaleString()}</p>
                </div>
              </>
            )}
          </>
        </AccordionTab>
        <AccordionTab header="Targets">
          {campaignInfo.groups.length > 0 ? (
            <>
              <ListBox
                className="mb-2"
                options={campaignInfo.groups}
                optionLabel="name"
                itemTemplate={(option) => (
                  <GroupListItem option={option} />
                )}
              />
              <label style={{ fontWeight: 'bold' }}>
                You will send this campaign to a total of
                {' '}
                {calculateTotalTargets(campaignInfo.groups)}
                {' '}
                people
              </label>
            </>
          ) : (
            <>
              <label style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Information not available
              </label>
              Target groups could have been removed
            </>
          )}
        </AccordionTab>
      </Accordion>
    </div>
  );
}

CampaignResumeComponent.propTypes = {
  campaignInfo: PropTypes.object,
};

CampaignResumeComponent.defaultProps = {
  campaignInfo: null,
};

export default CampaignResumeComponent;
