import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from 'primereact/checkbox';
import { importEmailTemplate } from '../../../../apis/templates.ts';

function ImportMailModalComponent({ visible, setVisible, setImportEmail }) {
  const schema = yup.object().shape({
    content: yup.string().required('Raw content is required'),
  });

  const defaultValues = {
    content: '',
    convert_links: true,
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    resetField,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  async function onSubmit(data) {
    const response = await importEmailTemplate(data);
    setImportEmail(response);
    resetField('content');
    resetField('convert_links');
    setVisible(false);
  }

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold">
        Import Email
      </span>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      modal
      style={{ width: '60rem' }}
      draggable={false}
      header={headerElement}
      onHide={() => setVisible(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left">
        <Controller
          name="content"
          control={control}
          render={({ field, fieldState }) => (
            <div>
              <InputTextarea
                id={field.name}
                placeholder="Raw Email Source"
                rows={10}
                autoResize
                value={field.value}
                className={`w-full ${fieldState.error ? 'p-invalid' : ''}`}
                onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                aria-describedby={`${field.name}-info`}
              />
              <div>
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />
        <Controller
          name="convert_links"
          control={control}
          render={({ field }) => (
            <label htmlFor={field.name}>
              <Checkbox
                id={field.name}
                inputId={field.name}
                checked={field.value}
                inputRef={field.ref}
                className="mr-2"
                onChange={(e) => field.onChange(e.checked)}
              />
              Change Links to Point to Landing Page
            </label>
          )}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button disabled={!isValid} label="Import" type="submit" icon="pi pi-file-import" />
        </div>
      </form>
    </Dialog>
  );
}

ImportMailModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  setImportEmail: PropTypes.func.isRequired,
};

export default ImportMailModalComponent;
