import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';

export function formatDate(dateString) {
  if (!dateString) return '-';
  const date = new Date(dateString);
  if (!date.getFullYear()) {
    return '-';
  }
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
    hour12: TextTrackCueList,
  };
  return new Intl.DateTimeFormat('en-ES', options).format(date);
}

export const RelationshipType = Object.freeze({
  ME: 'me',
  ANCESTOR: 'ancestor',
  DESCENDANT: 'descendant',
  EXTERNAL: 'external',
});

const ownerTypes = {
  me: {
    label: 'Me',
    color: 'info',
  },
  inherited: {
    label: 'Inherited',
    color: 'success',
  },
};

export const ownerInfo = (rowData) => {
  const owner = rowData.belongs_to === RelationshipType.ME ? RelationshipType.ME : 'inherited';
  return (
    <Tag
      value={ownerTypes[owner].label}
      severity={ownerTypes[owner].color}
    />
  );
};

export const landingPagesTypes = [
  {
    name: 'Learning', value: 'learning', color: '#ff9c9c', description: 'Teachable Moment', icon: 'pi pi-play',
  },
  {
    name: 'Credentials', value: 'credentials', color: '#1e3a8a', description: 'Compromise of Credentials', icon: 'pi pi-exclamation-circle',
  },
];

export const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
export const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
export const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

export const getFileTypeFromMimeType = (mimeType) => {
  if (!mimeType) return 'file';
  if (mimeType.startsWith('audio')) return 'audio';
  if (mimeType.startsWith('image')) return 'image';
  if (mimeType.startsWith('video')) return 'video';
  return 'file';
};

const behaviorsReasonsPalette = {
  S: {
    label: 'S',
    name: 'Bias',
    color: '#8e44ad',
  },
  M: {
    label: 'M',
    name: 'Motivation',
    color: '#f39c12',
  },
  C: {
    label: 'C',
    name: 'Behavior',
    color: '#16a085',
  },
};

export function behaviorsReasonsExtraction(behaviorsReasons) {
  const behaviorsReasonsExtracted = behaviorsReasons?.reduce((acc, item) => {
    const reason = item.BehaviorReason;
    acc.push(reason);
    return acc;
  }, []);
  return behaviorsReasonsExtracted;
}

export function formatBehaviorsReasonsPayload(behaviorReasons) {
  return behaviorReasons?.map((reason) => ({
    BehaviorReason: reason,
  }));
}

export function behaviorsReasonsBuilderType(type) {
  return (
    <Tag
      value={behaviorsReasonsPalette[type].name}
      style={{ background: behaviorsReasonsPalette[type].color }}
    />
  );
}

export function behaviorsReasonsBuilderChip(option) {
  return (
    <div className="p-chip" style={{ background: behaviorsReasonsPalette[option?.type].color }}>
      <span className="p-chip-text">
        {option?.externalId}
      </span>
    </div>
  );
}

export function behaviorsReasonsBuilderList(behaviorsReasons) {
  if (!behaviorsReasons || behaviorsReasons.length === 0) {
    return null;
  }
  const groupedByType = behaviorsReasons?.reduce((acc, item) => {
    const reason = item.BehaviorReason;
    if (!acc[reason.type]) {
      acc[reason.type] = [];
    }
    acc[reason.type].push(reason);
    return acc;
  }, {});

  return (
    <>
      {Object.entries(groupedByType).map(([type, items]) => {
        const names = items.map((item) => `- ${item.name}`).join('\n');

        return (
          <React.Fragment key={type}>
            <Tooltip target=".custom-target-icon" />
            <Tag
              value={behaviorsReasonsPalette[type].label}
              style={{
                background: behaviorsReasonsPalette[type].color,
                marginLeft: '0.2rem',
                cursor: 'pointer',
              }}
              className="custom-target-icon"
              data-pr-tooltip={names}
              data-pr-position="top"
            />
          </React.Fragment>
        );
      })}
    </>
  );
}

export const itemTemplate = (option) => (
  <div>
    <span>{option?.name}</span>
    <small style={{ marginLeft: '20px', color: '#888' }}>
      {option?.externalId}
    </small>
    <small style={{
      marginLeft: '1rem', color: '#888', justifyContent: 'flex-end',
    }}
    >
      {behaviorsReasonsBuilderType(option?.type)}
    </small>
  </div>
);

export const selectedItemTemplate = (option, selectedBehaviorsReasons) => (
  selectedBehaviorsReasons && selectedBehaviorsReasons.length > 0 && (
    behaviorsReasonsBuilderChip(option)
  )
);
