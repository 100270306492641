import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { FileUpload } from 'primereact/fileupload';
// import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { ProgressSpinner } from 'primereact/progressspinner';
import { createMedia, editMedia, getAllowedMediaTypes } from '../../../apis/medias.ts';
import { useToast } from '../../../context/ToastContext';
import './ManageMediaFormComponent.scss';
import {
  chooseOptions, uploadOptions, cancelOptions, getFileTypeFromMimeType,
} from '../../utils';

const fileStatusEnums = {
  Pending: { severity: 'info' },
  Complete: { severity: 'success' },
  Error: { severity: 'danger' },
  'Not Accepted': { severity: 'warning' },
  Uploading: { severity: 'constrast' },
};

export function MediaPreview({ file, uploadModal, allowedMediaType }) {
  if (!file) {
    return <div>No file provided</div>;
  }
  const fileType = getFileTypeFromMimeType(file.type);
  const previewURL = uploadModal ? URL.createObjectURL(file) : `media/${file.url}`;

  if (uploadModal) {
    if (!allowedMediaType.includes(file.type)) {
      return (
        <div className="flex flex-column align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle text-danger" style={{ fontSize: '2rem' }} />
          <span className="text-danger">File type not allowed</span>
        </div>
      );
    }
  }

  if (fileType === 'image') {
    return (
      <img
        src={previewURL}
        alt={file.name}
        width={uploadModal ? '100' : '500'}
        height={uploadModal ? '75' : '375'}
        style={{ objectFit: 'cover', borderRadius: '4px' }}
      />
    );
  }

  if (fileType === 'video') {
    return (
      <video
        controls
        width={uploadModal ? '100' : 'auto'}
        height={uploadModal ? '75' : '375'}
        style={{ objectFit: 'cover', borderRadius: '4px' }}
      >
        <source src={previewURL} type={file.type} />
        Your browser does not support the video tag.
      </video>
    );
  }

  if (fileType === 'audio') {
    return (
      <audio
        controls
        loop
        preload="auto"
        style={{
          width: uploadModal ? '100%' : '500px',
          height: uploadModal ? '75px' : '375px',
        }}
      >
        <source src={previewURL} type={file.type} />
        Your browser does not support the audio element.
      </audio>

    );
  }

  return (
    <iframe
      src={previewURL}
      title="Media Preview"
      style={{
        width: uploadModal ? '100px' : '100%',
        height: uploadModal ? '75px' : '300px',
        border: 'none',
        borderRadius: '4px',
      }}
      allow="autoplay; encrypted-media"
      allowFullScreen
    />

  );
}

MediaPreview.propTypes = {
  file: PropTypes.object,
  uploadModal: PropTypes.bool.isRequired,
  allowedMediaType: PropTypes.array,
};

MediaPreview.defaultProps = {
  file: null,
  allowedMediaType: [],
};

function ManageMediaFormComponent({ media, mode, setVisible }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allowedMediaType, setAllowedMediaTypes] = useState();
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const [fileStatus, setFileStatus] = useState({});
  const toast = useToast();
  const schema = yup.object().shape({
    name: yup.string().min(2, 'Media name must be at least 2 characters').max(255, 'Media name must be at most 255 characters').required('Media name is required'),
  });

  const defaultValues = {
    name: '',
    shared: false,
    active: false,
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    getValues,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    async function fetchData() {
      const response = await getAllowedMediaTypes();
      if (response.response?.data.success === false) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: response.response.data.message, life: 3000,
        });
        setAllowedMediaTypes(null);
      } else {
        setAllowedMediaTypes(response);
      }
    }
    fetchData();
  }, []);

  const updateFileStatus = (fileName, status) => {
    setFileStatus((prev) => ({
      ...prev,
      [fileName]: status,
    }));
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // TODO: prevent file upload
  const handleSelect = async (event) => {
    const { files } = event;
    const chunkSize = 5;
    const delayBetweenChunks = 1000;
    const initialStatus = {};

    files.forEach((file) => {
      if (!fileStatus[file.name]) {
        initialStatus[file.name] = 'Pending';
      }
    });
    setFileStatus((prev) => ({ ...prev, ...initialStatus }));

    const processChunk = async (chunk) => {
      const uploadPromises = chunk.map((file) => {
        if (fileStatus[file.name] === 'Complete' || fileStatus[file.name] === 'Error') {
          return Promise.resolve();
        }

        return new Promise(async (resolve) => {
          try {
            updateFileStatus(file.name, 'Uploading');
            const response = await createMedia(file);
            if (response?.response && response?.response?.status !== 201) {
              throw new Error(response.message);
            }
            updateFileStatus(file.name, 'Complete');
            toast.current.show({
              severity: 'success',
              summary: 'Upload Success',
              detail: `File ${file.name} uploaded successfully.`,
              life: 3000,
            });
          } catch (error) {
            updateFileStatus(file.name, 'Error');
            toast.current.show({
              severity: 'error',
              summary: 'Upload Error',
              detail: `Error uploading file ${file.name}: ${error.message}`,
              life: 3000,
            });
          }
          resolve();
        });
      });

      await Promise.all(uploadPromises);
    };

    const chunks = [];
    for (let i = 0; i < files.length; i += chunkSize) {
      chunks.push(files.slice(i, i + chunkSize));
    }

    await chunks.reduce(
      (promise, chunk) => promise
        .then(() => processChunk(chunk))
        .then(() => sleep(delayBetweenChunks)),
      Promise.resolve(),
    );

    setTotalSize(0);
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const removeFileStatus = (fileName) => {
    setFileStatus((prev) => {
      const updatedStatus = { ...prev };
      delete updatedStatus[fileName];
      return updatedStatus;
    });
  };

  const filePreviewTemplate = (file, props) => {
    const status = fileStatus[file.name] || 'Pending';
    const showDeleteButton = status !== 'Complete' && status !== 'Error';

    return (
      <div className="p-fileupload-item" style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <div style={{
          minWidth: '8vw', minHeight: '8vh', paddingRight: '10px', textAlign: 'left', flex: '0 0 auto',
        }}
        >
          <MediaPreview file={file} uploadModal allowedMediaType={allowedMediaType} />
        </div>

        <div style={{ paddingRight: '10px', textAlign: 'left', flex: '1 1 auto', wordBreak: 'break-all' }}>
          <span>{file.name}</span>
        </div>

        <div style={{ paddingRight: '30px' }}>
          <Tag
            severity={fileStatusEnums[status]?.severity || 'warning'}
            className="px-3 py-2"
          >
            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {status}
              {status === 'Uploading' && (
                <ProgressSpinner
                  style={{ width: '15px', height: '15px' }}
                  strokeWidth="8"
                />
              )}
            </span>
          </Tag>
        </div>

        <div>
          {showDeleteButton && (
            <Button
              type="button"
              icon="pi pi-times"
              className="p-button-outlined p-button-rounded p-button-danger"
              onClick={() => {
                // eslint-disable-next-line react/prop-types
                onTemplateRemove(file, props.onRemove);
                removeFileStatus(file.name);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    const { files } = e;
    const uploadedFiles = fileUploadRef.current.getFiles() || [];
    const uploadedFileNames = new Set(uploadedFiles.map((file) => file.name));
    Object.keys(files).forEach((key) => {
      const file = files[key];
      if (!allowedMediaType.includes(file.type)) {
        toast.current.show({
          severity: 'error',
          summary: 'Upload Error',
          detail: `File type not allowed: ${file.type}`,
          life: 3000,
        });
        updateFileStatus(file.name, 'Not Accepted');
        return;
      }
      if (!uploadedFileNames.has(file.name)) {
        _totalSize += file.size || 0;
      }
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      if (file.status !== 'Complete' && file.status !== 'Error') {
        _totalSize += file.size || 0;
      }
    });

    setTotalSize(_totalSize);
    toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  const onTemplateClear = () => {
    setFileStatus({});
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const {
      className, chooseButton, uploadButton, cancelButton,
    } = options;
    // const value = totalSize / 100000000;
    const formatedValue = fileUploadRef && fileUploadRef.current
      ? fileUploadRef.current.formatSize(totalSize).replace(/(\d+\.\d{2})\d*/, '$1')
      : '0.00 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>
            Total size:
            {' '}
            {formatedValue}
            {' '}
            {/* / 10 GB */}
          </span>
          {/* <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }} /> */}
        </div>
      </div>
    );
  };

  const emptyTemplate = () => (
    <div className="flex align-items-center flex-column">
      <i
        className="pi pi-image mt-3 p-5"
        style={{
          fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)',
        }}
      />
      <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
        Drag and Drop Image Here
      </span>
    </div>
  );

  useEffect(() => {
    if (media) {
      reset({
        name: mode === 'edit' ? media.name : '',
        shared: media.shared,
        active: media.active,
      });
    }
  }, [media]);

  async function onSubmit() {
    setIsSubmitting(true);
    const data = getValues();
    const sanitizedSendData = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );
    delete sanitizedSendData.url;
    const response = await editMedia({
      ...sanitizedSendData, id: media.id,
    });
    if (response.response?.data.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.response.data.message, life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'success', summary: 'Success', detail: mode === 'edit' ? 'Media edited' : 'Media created', life: 3000,
      });
      setVisible(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setIsSubmitting(false);
  }

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left ml-3">
      <div>
        {mode === 'edit' ? (
          <>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <div className="mb-2">
                  <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                    Name*
                  </label>
                  <InputText
                    id={field.name}
                    placeholder="Name"
                    value={field.value}
                    className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                    onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                    aria-describedby={`${field.name}-info`}
                  />
                  <div className="mb-1">
                    {getFormErrorMessage(field.name)}
                  </div>
                </div>
              )}
            />
            <div className="mb-5">
              <Controller
                name="shared"
                control={control}
                render={({ field }) => (
                  <label htmlFor={field.name} className="block">
                    <Checkbox
                      id={field.name}
                      inputId={field.name}
                      checked={field.value}
                      inputRef={field.ref}
                      className="mr-2"
                      onChange={(e) => {
                        field.onChange(e.checked);
                      }}
                    />
                    Share media
                    <Tooltip target=".custom-target-icon" className="w-3" />
                    <i
                      className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                      data-pr-tooltip="If you share the media, it will be accessible for use by other users associated with your account."
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{ cursor: 'pointer' }}
                    />
                  </label>
                )}
              />
            </div>
            <MediaPreview file={media} uploadModal={false} />
            {/* <div className="mt-5 mb-5">
              <Controller
                name="active"
                control={control}
                render={({ field }) => (
                  <label htmlFor={field.name}>
                    <Checkbox
                      id={field.name}
                      inputId={field.name}
                      checked={field.value}
                      inputRef={field.ref}
                      className="mr-2"
                      onChange={(e) => field.onChange(e.checked)}
                    />
                    Is Active
                    <Tooltip target=".custom-target-icon" className="w-3" />
                    <i
                      className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                      data-pr-tooltip="If media is active, it will be available for use in new pages and templates"
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center+7"
                      style={{ cursor: 'pointer' }}
                    />
                  </label>
                )}
              />
            </div> */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem' }}>
              <Button
                label={isSubmitting ? 'Saving...' : 'Save'}
                icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-save'}
                disabled={!isValid || isSubmitting}
                type="button"
                onClick={() => onSubmit()}
                autoFocus
              />
            </div>
          </>
        )
          : (
            <FileUpload
              ref={fileUploadRef}
              auto={false}
              accept={allowedMediaType?.join(', ')}
              chooseLabel="Select files"
              customUpload
              multiple
              maxFileSize={1 << 30} // 1GB in bytes
              onUpload={onTemplateUpload}
              onSelect={onTemplateSelect}
              onError={onTemplateClear}
              onClear={onTemplateClear}
              headerTemplate={headerTemplate}
              itemTemplate={filePreviewTemplate}
              emptyTemplate={emptyTemplate}
              chooseOptions={chooseOptions}
              uploadOptions={uploadOptions}
              cancelOptions={cancelOptions}
              uploadHandler={handleSelect}
            />
          )}
      </div>
    </form>
  );
}

ManageMediaFormComponent.propTypes = {
  media: PropTypes.object,
  mode: PropTypes.string,
  setVisible: PropTypes.func.isRequired,
};

ManageMediaFormComponent.defaultProps = {
  media: null,
  mode: null,
};

export default ManageMediaFormComponent;
