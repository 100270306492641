/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useSelector, useDispatch } from 'react-redux';
import ManageUserManagementFormComponent from '../ManageUserManagementFormComponent/ManageUserManagementFormComponent';
import { createUserManagement, editUserManagement, getUserManagement } from '../../../apis/usersManagement.ts';
import { useToast } from '../../../context/ToastContext';
import CustomSpinnerComponent from '../../CustomSpinnerComponent/CustomSpinnerComponent';
import getUserInfoState from '../../../redux/reducers/selectors/userInfo';
import { logout } from '../../../apis/authentication.ts';
import { cleanUserInfoState } from '../../../redux/reducers/actions/userInfoActions';

function ManageUserManagementModalComponent({
  visible, setVisible, userManagementId, users,
}) {
  const toast = useToast();
  const [user, setUser] = useState(null);
  const currentUser = useSelector(getUserInfoState);
  const [originalUsername, setOriginalUsername] = useState('');
  const [validForm, setValidForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const logoutHandler = async () => {
    const response = await logout();
    if (response.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.message, life: 3000,
      });
    } else {
      dispatch(cleanUserInfoState());
      window.location.href = '/login';
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (userManagementId && visible) {
        const response = await getUserManagement(userManagementId);
        if (response.response?.data.success === false || response.isAxiosError) {
          setVisible(false);
          toast.current.show({
            severity: 'error', summary: 'Error', detail: response.response.data.message || 'An unexpected error occurred getting the user information', life: 3000,
          });
          setUser(null);
        } else {
          setUser(response);
          setOriginalUsername(response?.username);
        }
      }
    }
    fetchData();
  }, [userManagementId, visible]);

  async function handleCreateUser() {
    setIsSubmitting(true);
    let response = null;
    if (userManagementId) {
      const {
        account_locked,
        id,
        password,
        password_change_required,
        role,
        username,
        parent,
        tenant_id,
        // eslint-disable-next-line no-unused-vars
        ...editUserData
      } = user;
      const parent_name = parent;
      response = await editUserManagement({
        // eslint-disable-next-line camelcase
        account_locked,
        id,
        password: password !== undefined ? password : '',
        // eslint-disable-next-line camelcase
        password_change_required,
        role: typeof role === 'string' ? role : role.slug,
        username,
        parent_name,
        tenant_id,
      });
    } else {
      const updatedUser = {
        ...user,
        parent_name: user.parent,
      };

      delete updatedUser.parent;
      response = await createUserManagement(updatedUser);
    }
    if (response.id) {
      toast.current.show({
        severity: 'success', summary: 'Success', detail: userManagementId ? 'User was edited' : 'User was created', life: 3000,
      });
      if (user?.id === currentUser?.id) {
        toast.current.show({
          severity: 'info', summary: 'Info', detail: 'You will logged out to update your information', life: 3000,
        });
      }
      setVisible(false);
      setTimeout(() => {
        if (user?.id === currentUser?.id) {
          logoutHandler();
        } else {
          window.location.reload();
        }
      }, 2000);
    } else {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.response.data.message || `An unexpected error occurred ${userManagementId ? 'editing' : 'creating'} the user`, life: 3000,
      });
    }
    setIsSubmitting(false);
  }

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold">
        {userManagementId ? `Edit ${originalUsername || ''} ` : 'Create a new '}
        user
      </span>
    </div>
  );

  const footerContent = (
    <div style={{ marginBottom: '-1rem', borderTop: '2px solid #ccc' }}>
      {user === null && userManagementId ? null : (
        <Button
          label={isSubmitting ? 'Saving...' : 'Save'}
          icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-save'}
          style={{ marginTop: '0.5rem' }}
          disabled={!validForm || isSubmitting}
          type="button"
          onClick={() => handleCreateUser()}
          autoFocus
        />
      )}
    </div>
  );

  const hideModal = () => {
    setVisible(false);
    setUser(null);
  };

  return (
    <Dialog
      visible={visible}
      modal
      draggable={false}
      header={headerElement}
      footer={footerContent}
      onHide={() => hideModal()}
    >
      {user === null && userManagementId ? <CustomSpinnerComponent text="user" />
        : (
          <ManageUserManagementFormComponent
            setUser={setUser}
            user={user}
            setValidForm={setValidForm}
            mode={userManagementId ? 'edit' : 'create'}
            users={users}
          />
        )}
    </Dialog>
  );
}

ManageUserManagementModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  userManagementId: PropTypes.number,
  users: PropTypes.array,
};

ManageUserManagementModalComponent.defaultProps = {
  userManagementId: null,
  users: [],
};

export default ManageUserManagementModalComponent;
