import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { getLandingPage } from '../../../apis/landingPages.ts';
import ManageLandingPageFormComponent from '../ManageLandingPageFormComponent/ManageLandingPageFormComponent';
import CustomSpinnerComponent from '../../CustomSpinnerComponent/CustomSpinnerComponent';
import { useToast } from '../../../context/ToastContext';

function ManageLandingPagesModalComponent({
  visible, setVisible, landingPageId, mode,
}) {
  const [landingPage, setLandingPage] = useState(null);
  const toast = useToast();

  useEffect(() => {
    async function fetchData() {
      if (landingPageId && visible) {
        const response = await getLandingPage(landingPageId);
        if (response.response?.data.success === false || response.isAxiosError) {
          setVisible(false);
          toast.current.show({
            severity: 'error', summary: 'Error', detail: response.response.data.message || 'An unexpected error occurred getting the page information', life: 3000,
          });
          setLandingPage(null);
        } else {
          setLandingPage(response);
        }
      }
    }
    fetchData();
  }, [landingPageId, visible]);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold">
        {mode === 'edit' ? `Edit ${landingPage?.name ? landingPage.name : ''} ` : 'Create a new'}
        {' '}
        landing page
      </span>
    </div>
  );

  const hideModal = () => {
    setVisible(false);
    setLandingPage(null);
  };

  return (
    <Dialog visible={visible} modal draggable={false} header={headerElement} style={{ width: '70rem' }} onHide={() => hideModal()}>
      {landingPage === null && landingPageId ? <CustomSpinnerComponent text="landing page" />
        : (
          <ManageLandingPageFormComponent
            landingPage={landingPage}
            mode={mode}
            setVisible={setVisible}
          />
        )}
    </Dialog>
  );
}

ManageLandingPagesModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  landingPageId: PropTypes.number,
  mode: PropTypes.string,
};

ManageLandingPagesModalComponent.defaultProps = {
  landingPageId: null,
  mode: null,
};

export default ManageLandingPagesModalComponent;
