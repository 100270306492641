import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { statusesInfo, deleteCampaignConfirmation } from '../../Campaigns/utils';
import { typeBodyTemplate, typeFilterTemplate, testTypeBodyTemplate } from '../../Campaigns/helpers';
import { formatDate, behaviorsReasonsBuilderList } from '../../utils';
import { useToast } from '../../../context/ToastContext';

const sentEmailHeader = (
  <>
    <Tooltip target=".custom-target-icon" />
    <i
      className="custom-target-icon pi pi-envelope p-text-secondary p-overlay-badge"
      data-pr-tooltip="Total sent"
      data-pr-position="right"
      data-pr-at="right+5 top+10"
      data-pr-my="left center-2"
      style={{ cursor: 'pointer', color: statusesInfo['Email Sent'].color }}
    />
  </>
);

const openedEmailHeader = (
  <>
    <Tooltip target=".custom-target-icon" />
    <i
      className="custom-target-icon pi pi-inbox p-text-secondary p-overlay-badge"
      data-pr-tooltip="Email opened"
      data-pr-position="right"
      data-pr-at="right+5 top+10"
      data-pr-my="left center-2"
      style={{ cursor: 'pointer', color: statusesInfo['Email Opened'].color }}
    />
  </>
);

const loginClickedHeader = (
  <>
    <Tooltip target=".custom-target-icon" />
    <i
      className="custom-target-icon pi pi-external-link p-text-secondary p-overlay-badge"
      data-pr-tooltip="Login link clicked"
      data-pr-position="right"
      data-pr-at="right+5 top+10"
      data-pr-my="left center-2"
      style={{ cursor: 'pointer', color: statusesInfo['Login Clicked'].color }}
    />
  </>
);

const submittedDataHeader = (
  <>
    <Tooltip target=".custom-target-icon" />
    <i
      className="custom-target-icon pi pi-exclamation-circle p-text-secondary p-overlay-badge"
      data-pr-tooltip="Submitted data"
      data-pr-position="right"
      data-pr-at="right+5 top+10"
      data-pr-my="left center-2"
      style={{ cursor: 'pointer', color: statusesInfo['Submitted Data'].color }}
    />
  </>
);

const learnClickedHeader = (
  <>
    <Tooltip target=".custom-target-icon" />
    <i
      className="custom-target-icon pi pi-book p-text-secondary p-overlay-badge"
      data-pr-tooltip="Learn link clicked"
      data-pr-position="right"
      data-pr-at="right+5 top+10"
      data-pr-my="left center-2"
      style={{ cursor: 'pointer', color: statusesInfo['Learn Clicked'].color }}
    />
  </>
);

const reportedEmailHeader = (
  <>
    <Tooltip target=".custom-target-icon" />
    <i
      className="custom-target-icon pi pi-megaphone p-text-secondary p-overlay-badge"
      data-pr-tooltip="Email reported"
      data-pr-position="right"
      data-pr-at="right+5 top+10"
      data-pr-my="left center-2"
      style={{ cursor: 'pointer', color: statusesInfo['Email Reported'].color }}
    />
  </>
);

function RecentCampaignsComponent({ campaigns }) {
  const toast = useToast();
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    created_date: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
    launch_date: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
    send_by_date: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
    completed_date: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.EQUALS },
      ],
    },
    type: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.EQUALS },
      ],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const [statuses] = useState(['In progress', 'Queued', 'Completed', 'Error']);

  const dateBodyTemplate = (date) => formatDate(date);
  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Campaigns</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );
  const dateFilterTemplate = (options) => <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;

  const header = renderHeader();
  // eslint-disable-next-line max-len
  const statusItemTemplate = (option) => <Tag value={option} severity={statusesInfo[option].label} />;
  const statusFilterTemplate = (options) => <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
  // eslint-disable-next-line max-len
  const statusBodyTemplate = (rowData) => <Tag value={rowData.status} severity={statusesInfo[rowData.status].label} />;

  const deleteBodyTemplate = (value) => <Button type="button" icon="pi pi-trash" severity="danger" rounded onClick={() => { deleteCampaignConfirmation(value.id, toast, navigate); }} />;

  const statsBodyTemplate = (value) => <Button type="button" icon="pi pi-chart-bar" rounded onClick={() => { navigate(`/campaign/${value.id}`); }} />;

  const customBodyTemplate = (rowData, field) => (rowData.type === 'SMS' ? '-' : rowData.stats[field]);

  const customLoginStatsTemplate = (rowData) => (rowData.credentials_page_id === 0 ? '-' : rowData.stats.submitted_data);

  const customSubmittedStatsTemplate = (rowData) => (rowData.credentials_page_id === 0 ? '-' : rowData.stats.submitted_data);

  const processedCampaigns = campaigns?.map((item) => ({
    ...item,
    created_date: new Date(item.created_date),
  }));

  return (
    <>
      <ConfirmDialog />
      <DataTable
        size="small"
        value={processedCampaigns}
        paginator
        header={header}
        rows={10}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 25, 50]}
        dataKey="id"
        selection={selectedCampaign}
        onSelectionChange={(e) => setSelectedCampaign(e.value)}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={['type', 'name', 'created_date', 'status']}
        emptyMessage="No campaigns found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} campaigns"
        sortField="created_date"
        sortOrder={-1}
      >
        <Column field="test_type" style={{ minWidth: '4rem' }} body={testTypeBodyTemplate} />
        <Column field="type" header="Type" sortable style={{ minWidth: '4rem' }} body={typeBodyTemplate} filter filterElement={typeFilterTemplate} />
        <Column field="name" header="Name" sortable filter filterPlaceholder="Search by first name" style={{ minWidth: '10rem', maxWidth: '34rem', wordBreak: 'break-word' }} />
        <Column header="Behaviors & Reasons" style={{ maxWidth: '5rem' }} body={(e) => behaviorsReasonsBuilderList(e?.behaviors_reasons)} />
        <Column field="created_date" header="Created date" sortable dataType="date" style={{ minWidth: '10rem' }} body={(e) => dateBodyTemplate(e.created_date)} filter filterElement={dateFilterTemplate} />
        <Column field="stats.sent" header={sentEmailHeader} sortable />
        <Column field="stats.opened" header={openedEmailHeader} sortable body={(rowData) => customBodyTemplate(rowData, 'opened')} />
        <Column field="stats.login_clicked" header={loginClickedHeader} sortable body={(rowData) => customLoginStatsTemplate(rowData)} />
        <Column field="stats.submitted_data" header={submittedDataHeader} sortable body={(rowData) => customSubmittedStatsTemplate(rowData)} />
        <Column field="stats.learn_clicked" header={learnClickedHeader} sortable />
        <Column field="stats.email_reported" header={reportedEmailHeader} sortable body={(rowData) => customBodyTemplate(rowData, 'email_reported')} />
        <Column field="status" header="Status" sortable filterMenuStyle={{ width: '12rem' }} style={{ minWidth: '6rem' }} body={statusBodyTemplate} filter filterElement={statusFilterTemplate} />
        <Column body={(e) => statsBodyTemplate(e)} />
        <Column body={(e) => deleteBodyTemplate(e)} />
      </DataTable>
    </>
  );
}

RecentCampaignsComponent.propTypes = {
  campaigns: PropTypes.array,
};

RecentCampaignsComponent.defaultProps = {
  campaigns: [],
};

export default RecentCampaignsComponent;
