import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import withContent from '../withContent';
import { getCampaign } from '../../apis/campaigns.ts';
import DoughnutComponent from '../../components/Campaigns/CampaignResultComponent/StatsComponents/DoughnutComponent';
import EmailResultsListComponent from '../../components/Campaigns/CampaignResultComponent/ResultsListComponent/EmailResultsListComponent';
import PolarAreaComponent from '../../components/Campaigns/CampaignResultComponent/StatsComponents/PolarAreaComponent';
import ActionsSectionComponent from '../../components/Campaigns/CampaignResultComponent/ActionsSectionComponent/ActionsSectionComponent';
import MapChartComponent from '../../components/Campaigns/CampaignResultComponent/StatsComponents/MapChartComponent';
import TimelineComponent from '../../components/Campaigns/CampaignResultComponent/StatsComponents/TimelineComponent';
import { useToast } from '../../context/ToastContext';
import SMSResultsListComponent from '../../components/Campaigns/CampaignResultComponent/ResultsListComponent/SMSResultsListComponent';
import SMSDoughnutComponent from '../../components/Campaigns/CampaignResultComponent/StatsComponents/SMSDoughnutComponent';
import SMSPolarAreaComponent from '../../components/Campaigns/CampaignResultComponent/StatsComponents/SMSPolarAreaComponent';
import CustomSpinnerComponent from '../../components/CustomSpinnerComponent/CustomSpinnerComponent';

const getUniqueCounts = (timeline) => {
  const seenEmailsByMessage = {
    'Error Sending Email': new Set(),
    'Error Sending SMS': new Set(),
    'Login Clicked': new Set(),
    'SMS Login Clicked': new Set(),
    'Email Opened': new Set(),
    'Email Reported': new Set(),
    'Submitted Data': new Set(),
    'SMS Submitted Data': new Set(),
    'Learn Clicked': new Set(),
    'SMS Learn Clicked': new Set(),
  };

  const counts = {
    'Error Sending Email': 0,
    'Error Sending SMS': 0,
    'Login Clicked': 0,
    'SMS Login Clicked': 0,
    'Email Opened': 0,
    'Email Reported': 0,
    'Submitted Data': 0,
    'SMS Submitted Data': 0,
    'Learn Clicked': 0,
    'SMS Learn Clicked': 0,
  };

  timeline?.forEach((item) => {
    if (seenEmailsByMessage[item.message]) {
      if (!seenEmailsByMessage[item.message].has(item.email)) {
        seenEmailsByMessage[item.message].add(item.email);
        counts[item.message] += 1;
      }
    }
  });

  return counts;
};

function CampaignResultPage() {
  const { id: campaignId } = useParams();
  const [campaign, setCampaign] = useState();
  const [error, setError] = useState(false);
  const [results, setResults] = useState();
  const [campaignType, setCampaignType] = useState();
  const toast = useToast();
  const navigate = useNavigate();
  let campaignStats = null;

  useEffect(() => {
    async function fetchData() {
      const response = await getCampaign(campaignId, true);
      if (response?.response?.data.success === false || response.isAxiosError) {
        setError(true);
        toast.current.show({
          severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? `There was a problem trying to get the campaign: ${response.response.data.message}` : 'An unexpected error occurred getting the campaign', life: 3000,
        });
      } else {
        setCampaign(response);
        const type = response?.sms_gateway !== 0 ? 'SMS' : 'Email';
        setCampaignType(type);
        if (localStorage.getItem('gophish.use_map') === 'true') {
          setResults(response);
        } else {
          setResults(null);
        }
      }
    }
    fetchData();
  }, [campaignId]);

  if (error) {
    return (
      <Button onClick={() => navigate('/campaigns')}>Return to campaigns</Button>
    );
  }

  if (!!campaign && Object.keys(campaign).length !== 0) {
    campaignStats = getUniqueCounts(campaign.timeline);
  }

  if (results === undefined || campaign === undefined) {
    return <CustomSpinnerComponent text="campaign" />;
  }

  return (
    <>
      <h1 className="ml-2 text-left" style={{ color: '#555', marginLeft: '10px' }}>{campaign?.name}</h1>
      <ActionsSectionComponent campaign={campaign} />
      {localStorage.getItem('gophish.see_timeline_graph') === 'true' && campaign && <TimelineComponent events={campaign?.timeline} />}
      <Divider />
      {campaignStats && (
        campaignType === 'Email'
          ? (
            <>
              {localStorage.getItem('gophish.see_doughnuts_graphs') === 'true' && <DoughnutComponent campaign={campaign} campaignStats={campaignStats} />}
              {localStorage.getItem('gophish.see_polar_area_graph') === 'true' && <PolarAreaComponent campaign={campaign} campaignStats={campaignStats} />}
              {localStorage.getItem('gophish.use_map') === 'true' && results?.results && <MapChartComponent results={results} />}
            </>
          ) : (
            <>
              {localStorage.getItem('gophish.see_doughnuts_graphs') === 'true' && <SMSDoughnutComponent campaign={campaign} campaignStats={campaignStats} />}
              {localStorage.getItem('gophish.see_polar_area_graph') === 'true' && <SMSPolarAreaComponent campaign={campaign} campaignStats={campaignStats} />}
            </>
          )
      )}
      <Divider />
      <h1 className="ml-2 text-left">Details</h1>
      {campaign && (
        campaignType === 'Email'
          ? (
            <EmailResultsListComponent
              data={campaign}
              campaignUrl={campaign.url}
              campaignType={campaignType}
            />
          )
          : (
            <SMSResultsListComponent
              data={campaign}
              campaignUrl={campaign.url}
              campaignType={campaignType}
            />
          )
      )}
    </>
  );
}

export default withContent(CampaignResultPage);
