import axiosHandler from './index';

const getTemplates = (detailed) => {
    return axiosHandler.get('/templates/', { params: { detailed: detailed } });
}

const getTemplate = (id) => {
    return axiosHandler.get(`/templates/${id}`);
}

const createTemplate = (data) => {
    return axiosHandler.post('/templates/', data);
}

const editTemplate = (data) => {
    return axiosHandler.put(`/templates/${data.id}`, data);
}

const deleteTemplate = (id) => {
    return axiosHandler.delete(`/templates/${id}`)
}

const importEmailTemplate = (data) => {
    return axiosHandler.post('/import/email', data)
}

export { getTemplates, getTemplate, createTemplate, editTemplate, deleteTemplate, importEmailTemplate };
