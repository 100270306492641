import axiosHandler from './index';

const getReportingSettings = () => {
    return axiosHandler.get('/imap/')
}

const createReportingSettings = (data) => {
    return axiosHandler.post('/imap/', data)
}

const validateReportingSettings = (data) => {
    return axiosHandler.post('/imap/validate', data)
}

export { getReportingSettings, createReportingSettings, validateReportingSettings }