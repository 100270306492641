/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import withContent from '../withContent';
import { getTemplates } from '../../apis/templates.ts';
import { getSmtpGateways } from '../../apis/sendingProfiles.ts';
import { getReportingSettings, createReportingSettings, validateReportingSettings } from '../../apis/reportingSettings.ts';
import { useToast } from '../../context/ToastContext';
import CustomSpinnerComponent from '../../components/CustomSpinnerComponent/CustomSpinnerComponent';
import InfoComponent from '../../components/UtilsComponents/InfoComponent';
import getUserInfoState from '../../redux/reducers/selectors/userInfo';

const reportingMailboxFormalOptions = [{
  name: 'Raw',
  value: 'raw',
}];

function ReportingSettingsPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [selectedReportingMailboxFormat, setSelectedReportingMailboxFormat] = useState();
  const [selectedReportingFeedbackTemplate, setSelectedReportingFeedbackTemplate] = useState();
  const [selectedReportingPositiveFeedback, setSelectedReportingPositiveFeedback] = useState();
  const [selectedReportingNegativeFeedback, setSelectedReportingNegativeFeedback] = useState();
  const [selectedReportingSendingProfile, setSelectedReportingSendingProfile] = useState();
  const [emailTemplatesOptions, setEmailTemplatesOptions] = useState();
  const [sendingProfilesOptions, setSendingProfilesOptions] = useState();
  const [reportingSettings, setReportingSettings] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { role } = useSelector(getUserInfoState);

  const schema = yup.object().shape({
    host: yup.string().required('IMAP host is required'),
    port: yup.number().min(0, 'Invalid IMAP Port').max(65535, 'Invalid IMAP Port').transform((value) => {
      if (value === null || Number.isNaN(value)) {
        return 993;
      }

      return value;
    })
      .required('IMAP port is required'),
    username: yup.string().min(2, 'Username must be at least 2 characters').max(255, 'Username must be at most 255 characters').required('Username is required'),
    password: yup.string().required('Password is mandatory'),
    imap_freq: yup.number().min(30, "Polling frequency can't be less than 30 seconds").nullable(),
    reporting_mailbox: yup.string()
      .test('mailbox-required', 'Reporting mailbox is required if a format is specified', (value, ctx) => {
        if (value?.length === 0 && !!ctx.parent.reporting_mailbox_format) {
          return false;
        }
        return true;
      }),
    reporting_smtp: yup.object().nullable()
      .test('sending-profile-required', 'Sending profile is required if a template is specified', (value, ctx) => {
        if (
          (!value?.name || value.name.length === 0)
          && (
            ctx.parent.reporting_template?.name?.length > 0
            || ctx.parent.positive_template?.name?.length > 0
            || ctx.parent.negative_template?.name?.length > 0
          )
        ) {
          return false;
        }
        return true;
      }),
  });

  const defaultValues = {
    enabled: false,
    host: '',
    port: '',
    username: '',
    password: '',
    tls: false,
    folder: '',
    imap_freq: '',
    restrict_domain: '',
    ignore_cert_errors: false,
    delete_reported_campaign_email: false,
    delete_reported_email: false,
    reporting_mailbox: '',
    reporting_mailbox_format: null,
    reporting_template: null,
    positive_template: null,
    negative_template: null,
    reporting_smtp: null,
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    register,
    getValues,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    async function fetchData() {
      const templates = await getTemplates(false);
      if (!Array.isArray(templates) && templates !== null) {
        setError(true);
        toast.current.show({
          severity: 'error', summary: 'Error', detail: templates?.response?.data?.message ? templates?.response?.data?.message : 'An unexpected error occurred getting the templates', life: 3000,
        });
      }
      const emailTemplates = templates.length && templates?.filter((template) => template?.template_type !== 'SMS');
      setEmailTemplatesOptions(emailTemplates || []);
      const smtps = await getSmtpGateways();
      if (!Array.isArray(smtps)) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: smtps?.response?.data?.message ? smtps?.response?.data?.message : 'An unexpected error occurred getting SMTP profiles', life: 3000,
        });
      }
      setSendingProfilesOptions(Array.isArray(smtps) ? smtps : []);
      const response = await getReportingSettings();
      if (!Array.isArray(response)) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? response?.response?.data?.message : 'An unexpected error occurred getting reporting settings', life: 3000,
        });
        setReportingSettings(null);
        setError(true);
      }
      if (response.length === 0) {
        setReportingSettings(null);
      } else {
        setReportingSettings(response[0]);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (reportingSettings) {
      setSelectedReportingMailboxFormat(reportingSettings.reporting_mailbox_format || undefined);
      setSelectedReportingFeedbackTemplate(
        reportingSettings.reporting_template?.name
          ? reportingSettings.reporting_template : undefined,
      );
      setSelectedReportingPositiveFeedback(
        reportingSettings.positive_template?.name
          ? reportingSettings.positive_template : undefined,
      );
      setSelectedReportingNegativeFeedback(
        reportingSettings.negative_template?.name
          ? reportingSettings.negative_template : undefined,
      );
      setSelectedReportingSendingProfile(
        reportingSettings.reporting_smtp?.name
          ? {
            id: reportingSettings.reporting_smtp.id,
            name: reportingSettings.reporting_smtp.name,
            modified_date: reportingSettings.reporting_smtp.modified_date,
            interface_type: reportingSettings.reporting_smtp.interface_type,
          } : undefined,
      );
      reset({
        enabled: reportingSettings.enabled,
        host: reportingSettings.host,
        port: reportingSettings.port,
        username: reportingSettings.username,
        password: reportingSettings.password,
        tls: reportingSettings.tls,
        folder: reportingSettings.folder,
        imap_freq: reportingSettings.imap_freq,
        restrict_domain: reportingSettings.restrict_domain,
        ignore_cert_errors: reportingSettings.ignore_cert_errors,
        delete_reported_campaign_email: reportingSettings.delete_reported_campaign_email,
        delete_reported_email: reportingSettings.delete_reported_email,
        reporting_mailbox: reportingSettings.reporting_mailbox,
        reporting_mailbox_format: reportingSettings.reporting_mailbox_format,
        reporting_template: reportingSettings.reporting_template,
        positive_template: reportingSettings.positive_template,
        negative_template: reportingSettings.negative_template,
        reporting_smtp: reportingSettings.reporting_smtp,
      });
    }
  }, [reportingSettings]);

  const onSubmit = async () => {
    setIsSubmitting(true);
    const data = getValues();
    const sanitizedSendData = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );
    const response = await createReportingSettings({
      ...sanitizedSendData,
      port: sanitizedSendData.port?.toString() || '993',
      imap_freq: sanitizedSendData.imap_freq?.toString(),
    });
    if (response.response?.data.success === false || response.isAxiosError) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.response.data.message || 'An unexpected error occurred saving the reporting settings', life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'success', summary: 'Success', detail: response.message, life: 3000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setIsSubmitting(false);
  };

  const validateImap = async () => {
    setIsValidating(true);
    const data = getValues();
    const sanitizedSendData = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );
    const response = await validateReportingSettings({
      ...sanitizedSendData,
      port: sanitizedSendData.port?.toString() || '993',
      imap_freq: sanitizedSendData.imap_freq?.toString(),
    });
    if (response?.success !== true) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.message, life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'success', summary: 'Success', detail: response.message, life: 3000,
      });
    }
    setIsValidating(false);
  };

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  if (error) {
    return (
      <>
        <InfoComponent
          title="Error"
          message="Please try refreshing the page. If the error persists, please contact us"
          severity="error"
        />
        <Button onClick={() => window.location.reload()}>Refresh</Button>
      </>
    );
  }

  if (role === 'customer') {
    navigate('/');
  }

  if ((reportingSettings === undefined
    || sendingProfilesOptions === undefined
    || emailTemplatesOptions === undefined)
    && !error) {
    return <CustomSpinnerComponent text="reporting settings" />;
  }

  return (
    <div>
      <div className="text-xl text-500 mb-5">Monitor an IMAP account for emails reported by users.</div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left ml-3">
        <ul className="list-none p-0 m-0">
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="enabled"
              control={control}
              render={({ field }) => (
                <label htmlFor={field.name}>
                  <Checkbox
                    id={field.name}
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    className="mr-2"
                    onChange={(e) => {
                      field.onChange(e.checked);
                    }}
                  />
                  Enable Email Account Monitoring
                </label>
              )}
            />
          </li>
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="host"
              control={control}
              render={({ field }) => (
                <>
                  <label className="text-500 w-6 md:w-2 font-medium" htmlFor={field.name}>IMAP Host*</label>
                  <div className="w-full md:w-10 md:flex-order-0 flex-order-1">
                    <InputText
                      id={field.name}
                      value={field.value}
                      placeholder="imap.example.com"
                      className="w-full md:w-30rem"
                      onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                      aria-describedby={`${field.name}-info`}
                    />
                  </div>
                </>
              )}
            />
          </li>
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="port"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-2 font-medium" htmlFor={field.name}>IMAP Port*</label>
                  <div className="w-full md:w-10 md:flex-order-0 flex-order-1">
                    <div>
                      {getFormErrorMessage(field.name)}
                    </div>
                    <InputNumber
                      id={field.name}
                      value={field.value || 993}
                      placeholder="993"
                      onValueChange={
                        (e) => { field.onChange(e.value); trigger(field.name); }
                      }
                      onChange={
                        (e) => { field.onChange(e.value); trigger(field.name); }
                      }
                      showButtons
                      buttonLayout="horizontal"
                      step={1}
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      aria-describedby={`${field.name}-info`}
                      className={`w-full md:w-10rem ${fieldState.error ? 'p-invalid' : ''}`}
                      locale="es-ES"
                    />
                  </div>
                </>
              )}
            />
          </li>
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="username"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-2 font-medium" htmlFor={field.name}>IMAP Username*</label>
                  <div className="w-full md:w-10 md:flex-order-0 flex-order-1">
                    <div>
                      {getFormErrorMessage(field.name)}
                    </div>
                    <InputText
                      id={field.name}
                      value={field.value}
                      placeholder="Username"
                      className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                      aria-describedby={`${field.name}-info`}
                    />
                  </div>
                </>
              )}
            />
          </li>
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-2 font-medium" htmlFor={field.name}>
                    IMAP Password*
                  </label>
                  <div className="w-full md:w-10 md:flex-order-0 flex-order-1">
                    <div>
                      {getFormErrorMessage(field.name)}
                    </div>
                    <span className="p-input-icon-right">
                      <InputText
                        id={field.name}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register(field.name)}
                        type={showPassword ? 'text' : 'password'}
                        value={field.value}
                        placeholder="Password"
                        className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                        onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                        aria-describedby={`${field.name}-info`}
                      />
                      <i
                        className={showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                        onClick={() => setShowPassword(!showPassword)}
                        tabIndex="0"
                        role="button"
                        aria-label="Toggle New Password Visibility"
                        onKeyDown={(e) => e.key === 'Enter' && setShowPassword(!showPassword)}
                      />
                    </span>
                  </div>
                </>
              )}
            />
          </li>
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="tls"
              control={control}
              render={({ field }) => (
                <>
                  <label className="text-500 w-6 md:w-2 font-medium" htmlFor={field.name}>
                    Use TLS
                  </label>
                  <div className="w-full md:w-10 md:flex-order-0 flex-order-1">
                    <Checkbox
                      id={field.name}
                      inputId={field.name}
                      checked={field.value}
                      inputRef={field.ref}
                      className="mr-2"
                      onChange={(e) => {
                        field.onChange(e.checked);
                      }}
                    />
                  </div>
                </>
              )}
            />
          </li>
        </ul>
        <Divider />
        <Fieldset toggleable legend="Advanced Settings">
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="folder"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>Folder</label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <div>
                        {getFormErrorMessage(field.name)}
                      </div>
                      <InputText
                        id={field.name}
                        value={field.value}
                        placeholder="Leave blank for default of INBOX."
                        className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                        onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                        aria-describedby={`${field.name}-info`}
                      />
                    </div>
                  </>
                )}
              />
            </li>
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="imap_freq"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Polling frequency
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="How often to check for new emails. 30 seconds minimum. Leave blank for default of every 60 seconds."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <div>
                        {getFormErrorMessage(field.name)}
                      </div>
                      <InputNumber
                        id={field.name}
                        value={field.value}
                        onValueChange={
                          (e) => { field.onChange(e.value); trigger(field.name); }
                        }
                        showButtons
                        buttonLayout="horizontal"
                        step={1}
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        aria-describedby={`${field.name}-info`}
                        className={`w-full md:w-10rem ${fieldState.error ? 'p-invalid' : ''}`}
                        locale="es-ES"
                      />
                    </div>
                  </>
                )}
              />
            </li>
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="restrict_domain"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Restrict to domain
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Only check emails reported from the supplied domain. Leave blank for all domains."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <div>
                        {getFormErrorMessage(field.name)}
                      </div>
                      <InputText
                        id={field.name}
                        value={field.value}
                        placeholder="Add a domain (e.g. widgets.com)"
                        className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                        onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                        aria-describedby={`${field.name}-info`}
                      />
                    </div>
                  </>
                )}
              />
            </li>
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="ignore_cert_errors"
                control={control}
                render={({ field }) => (
                  <>
                    <label className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Ignore Certificate Errors
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Ignore common certificate errors such as self-signed certs (exposes you to MiTM attacks - use carefully!)"
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <Checkbox
                        id={field.name}
                        inputId={field.name}
                        checked={field.value}
                        inputRef={field.ref}
                        className="mr-2"
                        onChange={(e) => {
                          field.onChange(e.checked);
                        }}
                      />
                    </div>
                  </>
                )}
              />
            </li>
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="delete_reported_campaign_email"
                control={control}
                render={({ field }) => (
                  <>
                    <label className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Delete campaigns emails
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Delete campaign emails after they've been reported."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <Checkbox
                        id={field.name}
                        inputId={field.name}
                        checked={field.value}
                        inputRef={field.ref}
                        className="mr-2"
                        onChange={(e) => {
                          field.onChange(e.checked);
                        }}
                      />
                    </div>
                  </>
                )}
              />
            </li>
          </ul>
        </Fieldset>
        <Divider />
        <Fieldset toggleable legend="Custom Reporting Settings">
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="delete_reported_email"
                control={control}
                render={({ field }) => (
                  <>
                    <label className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Delete emails
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Delete campaign emails after they've been reported."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <Checkbox
                        id={field.name}
                        inputId={field.name}
                        checked={field.value}
                        inputRef={field.ref}
                        className="mr-2"
                        onChange={(e) => {
                          field.onChange(e.checked);
                        }}
                      />
                    </div>
                  </>
                )}
              />
            </li>
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="reporting_mailbox"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Reporting mailbox
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Mailbox to send feedback for emails reported by users."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <div>
                        {getFormErrorMessage(field.name)}
                      </div>
                      <InputText
                        id={field.name}
                        value={field.value}
                        placeholder="Reporting mailbox."
                        className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                        onChange={(e) => { field.onChange(e.target.value); trigger(field.name); trigger('reporting_mailbox_format'); }}
                        aria-describedby={`${field.name}-info`}
                      />
                    </div>
                  </>
                )}
              />
            </li>
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="reporting_mailbox_format"
                control={control}
                render={({ field }) => (
                  <>
                    <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Reporting mailbox format
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Format of the reporting mailbox."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <div>
                        {getFormErrorMessage(field.name)}
                      </div>
                      <Dropdown
                        id={field.name}
                        value={selectedReportingMailboxFormat}
                        onChange={(e) => {
                          setSelectedReportingMailboxFormat(e.value);
                          setValue(field.name, e.value);
                          trigger(field.name);
                          trigger('reporting_mailbox');
                        }}
                        options={reportingMailboxFormalOptions}
                        optionLabel="name"
                        placeholder="Select a format"
                        className="w-full md:w-30rem"
                        showClear
                      />
                    </div>
                  </>
                )}
              />
            </li>
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="reporting_template"
                control={control}
                render={({ field }) => (
                  <>
                    <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Reporting feedback template
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Reporting feedback template. Leave blank if no reporting feedback to the reporting mailbox is needed."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <div>
                        {getFormErrorMessage(field.name)}
                      </div>
                      <Dropdown
                        id={field.name}
                        value={
                          emailTemplatesOptions.find(
                            (i) => i.id === selectedReportingFeedbackTemplate?.id,
                          )
                        }
                        onChange={(e) => {
                          setSelectedReportingFeedbackTemplate(e.value);
                          setValue(field.name, { name: e.value?.name || undefined });
                          trigger(field.name);
                          trigger('reporting_smtp');
                        }}
                        options={emailTemplatesOptions}
                        optionLabel="name"
                        placeholder="Select a template"
                        className="w-full md:w-30rem"
                        showClear
                      />
                    </div>
                  </>
                )}
              />
            </li>
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="positive_template"
                control={control}
                render={({ field }) => (
                  <>
                    <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Positive feedback template
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="End-user positive feedback template. Leave blank if no positive feedback to the user is needed."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <div>
                        {getFormErrorMessage(field.name)}
                      </div>
                      <Dropdown
                        id={field.name}
                        value={
                          emailTemplatesOptions.find(
                            (i) => i.id === selectedReportingPositiveFeedback?.id,
                          )
                        }
                        onChange={(e) => {
                          setSelectedReportingPositiveFeedback(e.value);
                          setValue(field.name, { name: e.value?.name || undefined });
                          trigger(field.name);
                          trigger('reporting_smtp');
                        }}
                        options={emailTemplatesOptions}
                        optionLabel="name"
                        placeholder="Select a template"
                        className="w-full md:w-30rem"
                        showClear
                      />
                    </div>
                  </>
                )}
              />
            </li>
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="negative_template"
                control={control}
                render={({ field }) => (
                  <>
                    <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Negative feedback template
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="End-user negative feedback template. Leave blank if no negative feedback to the user is needed."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <div>
                        {getFormErrorMessage(field.name)}
                      </div>
                      <Dropdown
                        id={field.name}
                        value={
                          emailTemplatesOptions.find(
                            (i) => i.id === selectedReportingNegativeFeedback?.id,
                          )
                        }
                        onChange={(e) => {
                          setSelectedReportingNegativeFeedback(e.value);
                          setValue(field.name, { name: e.value?.name || undefined });
                          trigger(field.name);
                          trigger('reporting_smtp');
                        }}
                        options={emailTemplatesOptions}
                        optionLabel="name"
                        placeholder="Select a template"
                        className="w-full md:w-30rem"
                        showClear
                      />
                    </div>
                  </>
                )}
              />
            </li>
            <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
              <Controller
                name="reporting_smtp"
                control={control}
                render={({ field }) => (
                  <>
                    <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-3 font-medium" htmlFor={field.name}>
                      Sending Profile
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Sending Profile to send end-user feedback templates."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center+7"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="w-full md:w-9 md:flex-order-0 flex-order-1">
                      <div>
                        {getFormErrorMessage(field.name)}
                      </div>
                      <Dropdown
                        id={field.name}
                        value={
                          sendingProfilesOptions.find(
                            (i) => i.id === selectedReportingSendingProfile?.id,
                          )
                        }
                        onChange={(e) => {
                          setSelectedReportingSendingProfile(e.value);
                          setValue(field.name, { name: e.value?.name || undefined });
                          trigger(field.name);
                        }}
                        options={sendingProfilesOptions}
                        optionLabel="name"
                        placeholder="Select a sending profile"
                        className="w-full md:w-30rem"
                        showClear
                      />
                    </div>
                  </>
                )}
              />
            </li>
          </ul>
        </Fieldset>
        <div>
          <Button
            label={isSubmitting ? 'Saving...' : 'Save'}
            icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-save'}
            className="w-2 md:w-2 m-6"
            disabled={!isValid || isSubmitting}
            type="button"
            onClick={() => onSubmit()}
            autoFocus
          />
          <Button
            severity="secondary"
            label={isValidating ? 'Validating...' : 'Validate'}
            icon={isValidating ? 'pi pi-spin pi-spinner' : 'pi pi-wrench'}
            className="w-2 md:w-2 m-6"
            disabled={isValidating}
            type="button"
            onClick={() => validateImap()}
            autoFocus
          />
        </div>
      </form>
    </div>
  );
}

export default withContent(ReportingSettingsPage);
