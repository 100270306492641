import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import ManageMediaModalComponent from '../ManageMediaModalComponent/ManageMediaModalComponent';
import { deleteMedia, deleteMedias } from '../../../apis/medias.ts';
import { useToast } from '../../../context/ToastContext';
import { MediaPreview } from '../ManageMediaFormComponent/ManageMediaFormComponent';
import { formatDate, RelationshipType, getFileTypeFromMimeType } from '../../utils';
import './MediaListComponent.scss';

const fileTypes = [
  { label: 'Audio', value: 'audio' },
  { label: 'Image', value: 'image' },
  { label: 'Video', value: 'video' },
  { label: 'File', value: 'file' },
];

async function acceptDeleteMedia(media, toast) {
  let response = {};
  if (media.length === 1) {
    response = await deleteMedia(media[0].id);
  } else {
    const mediaIds = media.map((obj) => obj.id);
    response = await deleteMedias(mediaIds);
  }
  if (response.success) {
    toast.current.show({
      severity: 'success', summary: 'Success', detail: response.message, life: 3000,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } else {
    toast.current.show({
      severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? `There was a problem trying to remove the media: ${response.response.data.message}` : 'An unexpected error occurred deleting the media', life: 3000,
    });
  }
}

const deleteMediaConfirmation = (media, toast) => {
  confirmDialog({
    message: (
      <div>
        <p>
          Do you want to delete
          {' '}
          {media.length === 1 ? 'this' : 'these'}
          {' '}
          media?
        </p>
        {media.length === 1 ? (
          <MediaPreview file={media[0]} uploadModal={false} />
        ) : (
          <ul>
            {media.map((m) => (
              <li key={m.id}>
                {m.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    ),
    header: `Delete ${media.length === 1 ? media[0]?.name : ''} media`,
    headerStyle: { wordBreak: 'break-all' },
    defaultFocus: 'reject',
    acceptClassName: 'p-button-danger',
    accept: () => acceptDeleteMedia(media, toast),
  });
};

function MediaListComponent({ media }) {
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState();
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [urlModalVisible, setUrlModalVisible] = useState(false);
  const [selectedUrlMedia, setSelectedUrlMedia] = useState(null);
  const settingsRef = useRef(null);
  const toast = useToast();

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      ],
    },
    url: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      ],
    },
    created_at: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
    type: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.EQUALS },
      ],
    },
    active: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      ],
    },
  });

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const dateFilterTemplate = (options) => <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
  const dateBodyTemplate = (date) => formatDate(date);

  const typeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={fileTypes}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      placeholder="Select a category"
      className="p-column-filter"
    />
  );

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Media</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );
  const header = renderHeader();
  const actions = () => (
    [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => { setMode('edit'); setVisible(true); },
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => { deleteMediaConfirmation(selectedMedia, toast); },
      },
    ]);

  const actionBodyTemplate = (value) => (
    <Button type="button" disabled={value.belongs_to === RelationshipType.ANCESTOR} icon="pi pi-cog" className="justify-content-center align-items-center h-3rem w-3rem border-circle" style={{ marginLeft: '-0.5rem' }} onClick={(e) => { settingsRef.current.toggle(e); setSelectedMedia([value]); }}>
      <TieredMenu model={actions()} popup ref={settingsRef} style={{ width: 'auto' }} />
    </Button>
  );

  const processedMedia = media?.map((item) => ({
    ...item,
    created_at: new Date(item.created_at),
    category: getFileTypeFromMimeType(item.type),
  }));

  return (
    media?.length > 0 && (
      <>
        <Button
          className="mt-2"
          label="Delete"
          severity="danger"
          style={{
            position: 'absolute',
            right: '10px',
          }}
          disabled={selectedMedia.length === 0}
          onClick={() => deleteMediaConfirmation(selectedMedia, toast)}
        />
        {urlModalVisible && selectedUrlMedia && (
          <Dialog
            visible={urlModalVisible}
            onHide={() => setUrlModalVisible(false)}
            header="Media Preview"
          >
            <MediaPreview file={selectedUrlMedia} uploadModal={false} />
          </Dialog>
        )}
        <ConfirmDialog />
        <ManageMediaModalComponent
          visible={visible}
          setVisible={setVisible}
          media={selectedMedia[0]}
          mode={mode}
        />
        <DataTable
          size="small"
          value={processedMedia}
          paginator
          header={header}
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          selectionMode="checkbox"
          selection={selectedMedia}
          onSelectionChange={(e) => setSelectedMedia(e.value)}
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={['name', 'url', 'created_at', 'type', 'is_active']}
          emptyMessage="No media found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} media"
          sortField="created_at"
          sortOrder={-1}
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: '3rem' }}
            bodyStyle={{ textAlign: 'center' }}
            bodyClassName={(rowData) => (rowData.belongs_to === RelationshipType.ANCESTOR ? 'hide-checkbox' : '')}
          />
          <Column field="name" header="Name" sortable filter filterPlaceholder="Search by name" style={{ minWidth: '8rem', maxWidth: '36rem', wordBreak: 'break-word' }} />
          <Column field="category" header="Type" sortable filter filterElement={typeFilterTemplate} style={{ maxWidth: '7rem' }} />
          <Column
            field="url"
            header="URL"
            sortable
            filter
            filterPlaceholder="Search by URL"
            style={{ minWidth: '14rem', wordBreak: 'break-word' }}
            body={(rowData) => (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedUrlMedia(rowData);
                  setUrlModalVisible(true);
                }}
              >
                {rowData.url}
              </a>
            )}
          />
          <Column field="created_at" header="Created date" sortable dataType="date" style={{ minWidth: '12rem' }} body={(e) => dateBodyTemplate(e.created_at)} filter filterElement={dateFilterTemplate} />
          {/* <Column field="active" header="Active" sortable style={{ minWidth: '2rem' }} /> */}
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={(e) => actionBodyTemplate(e)} />
        </DataTable>
      </>
    )
  );
}

MediaListComponent.propTypes = {
  media: PropTypes.array,
};

MediaListComponent.defaultProps = {
  media: [],
};

export default MediaListComponent;
