import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputText } from 'primereact/inputtext';
import { importLandingPage } from '../../../../apis/landingPages.ts';

function ImportSiteModalComponent({ visible, setVisible, setImportSite }) {
  const schema = yup.object().shape({
    url: yup.string().url('The URL must be a valid one').required('URL is required'),
  });

  const defaultValues = {
    url: '',
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    resetField,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  async function onSubmit(data) {
    const response = await importLandingPage({ ...data, include_resources: false });
    setImportSite(response);
    resetField('url');
    setVisible(false);
  }

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold">
        Import Site
      </span>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      modal
      style={{ width: '60rem' }}
      draggable={false}
      header={headerElement}
      onHide={() => setVisible(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left">
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <div className="mb-2">
              <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                URL*
              </label>
              <InputText
                id={field.name}
                placeholder="https://google.com"
                value={field.value}
                className="w-full"
                onChange={(e) => { field.onChange(e.target.value); trigger(field.url); }}
                aria-describedby={`${field.name}-info`}
              />
              <div className="mb-1">
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button disabled={!isValid} label="Import" type="submit" icon="pi pi-file-import" />
        </div>
      </form>
    </Dialog>
  );
}

ImportSiteModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  setImportSite: PropTypes.func.isRequired,
};

export default ImportSiteModalComponent;
