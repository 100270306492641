import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import ManageMediaFormComponent from '../ManageMediaFormComponent/ManageMediaFormComponent';
import CustomSpinnerComponent from '../../CustomSpinnerComponent/CustomSpinnerComponent';

function ManageMediaModalComponent({
  visible, setVisible, media, mode,
}) {
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold" style={{ wordBreak: 'break-all' }}>
        {mode === 'edit' ? `Edit ${media?.name ? media.name : ''} ` : 'Upload'}
        {' '}
        media
      </span>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      modal
      style={{ width: mode !== 'edit' && '70vw' }}
      draggable={false}
      header={headerElement}
      onHide={() => {
        setVisible(false);
        if (mode !== 'edit') {
          window.location.reload();
        }
      }}
    >
      {(mode === 'edit' && media === null) ? null : media === undefined && mode === 'edit' ? (
        <CustomSpinnerComponent text="media" />
      ) : (
        <ManageMediaFormComponent media={media} mode={mode} setVisible={setVisible} />
      )}
    </Dialog>
  );
}

ManageMediaModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  media: PropTypes.object,
  mode: PropTypes.string,
};

ManageMediaModalComponent.defaultProps = {
  media: null,
  mode: null,
};

export default ManageMediaModalComponent;
