import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from 'react-router-dom';
import ManageCampaignModalComponent from '../ManageCampaignModalComponent/ManageCampaignModalComponent';
import { statusesInfo, deleteCampaignConfirmation } from '../utils';
import { typeBodyTemplate, typeFilterTemplate, testTypeBodyTemplate } from '../helpers';
import { formatDate, behaviorsReasonsBuilderList } from '../../utils';
import { useToast } from '../../../context/ToastContext';

function CampaignsListComponent({ campaigns, archived }) {
  const [visible, setVisible] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const toast = useToast();
  const settingsRef = useRef(null);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.EQUALS },
      ],
    },
    name: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    created_date: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
    launch_date: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
    send_by_date: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
    completed_date: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.EQUALS },
      ],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const [statuses] = useState(['In progress', 'Queued', 'Completed', 'Error']);

  const dateBodyTemplate = (date) => formatDate(date);
  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Campaigns</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );
  const dateFilterTemplate = (options) => <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;

  const header = renderHeader();
  // eslint-disable-next-line max-len
  const statusItemTemplate = (option) => <Tag value={option} severity={statusesInfo[option].label} />;
  const statusFilterTemplate = (options) => <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
  // eslint-disable-next-line max-len
  const statusBodyTemplate = (rowData) => <Tag value={rowData.status} severity={statusesInfo[rowData.status].label} />;

  const actions = () => (
    [
      {
        label: 'Make a copy',
        icon: 'pi pi-copy',
        command: () => { setVisible(true); },
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => { deleteCampaignConfirmation(campaignId, toast, navigate); },
      },
    ]);

  const actionBodyTemplate = (value) => (
    <Button type="button" icon="pi pi-cog" className="justify-content-center align-items-center h-3rem w-3rem border-circle" style={{ marginLeft: '-0.5rem' }} onClick={(e) => { settingsRef.current.toggle(e); setCampaignId(value.id); }}>
      <TieredMenu model={actions()} popup ref={settingsRef} style={{ width: 'auto' }} />
    </Button>
  );
  const statsBodyTemplate = (value) => <Button type="button" icon="pi pi-chart-bar" rounded onClick={() => { navigate(`/campaign/${value.id}`); }} />;

  const processedCampaigns = campaigns?.map((item) => ({
    ...item,
    created_date: new Date(item.created_date),
    launch_date: new Date(item.launch_date),
    send_by_date: new Date(item.completed_date),
  }));

  return (
    <>
      <ConfirmDialog />
      {visible && (
        <ManageCampaignModalComponent
          visible={visible}
          setVisible={setVisible}
          campaignId={campaignId}
        />
      )}
      <DataTable
        size="small"
        value={processedCampaigns}
        paginator
        header={header}
        rows={10}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 25, 50]}
        dataKey="id"
        selection={selectedCampaign}
        onSelectionChange={(e) => setSelectedCampaign(e.value)}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={['type', 'name', 'created_date', 'launch_date', 'status']}
        emptyMessage="No campaigns found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} campaigns"
        sortField="created_date"
        sortOrder={-1}
      >
        <Column field="test_type" style={{ minWidth: '4rem' }} body={testTypeBodyTemplate} />
        <Column field="type" header="Type" sortable style={{ minWidth: '4rem' }} body={typeBodyTemplate} filter filterElement={typeFilterTemplate} />
        <Column field="name" header="Name" sortable filter filterPlaceholder="Search by first name" style={{ minWidth: '12rem', maxWidth: '34rem', wordBreak: 'break-word' }} />
        <Column header="Behaviors & Reasons" style={{ maxWidth: '5rem' }} body={(e) => behaviorsReasonsBuilderList(e?.behaviors_reasons)} />
        <Column field="created_date" header="Created date" sortable dataType="date" style={{ minWidth: '12rem' }} body={(e) => dateBodyTemplate(e.created_date)} filter filterElement={dateFilterTemplate} />
        <Column field="launch_date" header="Launch date" sortable dataType="date" style={{ minWidth: '12rem' }} body={(e) => dateBodyTemplate(e.launch_date)} filter filterElement={dateFilterTemplate} />
        {archived && <Column field="send_by_date" header="End date" sortable dataType="date" style={{ minWidth: '12rem' }} body={(e) => dateBodyTemplate(e.send_by_date)} filter filterElement={dateFilterTemplate} />}
        <Column field="status" header="Status" sortable filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '8rem' }} body={statusBodyTemplate} filter filterElement={statusFilterTemplate} />
        <Column body={(e) => statsBodyTemplate(e)} />
        <Column body={(e) => actionBodyTemplate(e)} />
      </DataTable>
    </>
  );
}

CampaignsListComponent.propTypes = {
  campaigns: PropTypes.array,
  archived: PropTypes.bool,
};

CampaignsListComponent.defaultProps = {
  campaigns: [],
  archived: false,
};

export default CampaignsListComponent;
