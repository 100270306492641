import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import ManageCampaignFormComponent from '../ManageCampaignFormComponent/ManageCampaignFormComponent';
import { getSmtpGateways, getSmsGateways } from '../../../apis/sendingProfiles.ts';
import { getCampaign } from '../../../apis/campaigns.ts';
import { useToast } from '../../../context/ToastContext';
import InfoComponent from '../../UtilsComponents/InfoComponent';
import CustomSpinnerComponent from '../../CustomSpinnerComponent/CustomSpinnerComponent';

function ManageCampaignModalComponent({
  visible, setVisible, campaignId,
}) {
  const [campaign, setCampaign] = useState(null);
  const [sendingProfiles, setSendingProfiles] = useState(null);
  const [error, setError] = useState(false);
  const toast = useToast();
  useEffect(() => {
    async function fetchData() {
      if (campaignId) {
        setCampaign(await getCampaign(campaignId, false));
      }
    }
    fetchData();
  }, [campaignId]);

  useEffect(() => {
    async function fetchData() {
      const smtps = await getSmtpGateways();
      if (!Array.isArray(smtps)) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: smtps?.response?.data?.message ? smtps?.response?.data?.message : 'An unexpected error occurred getting SMTP profiles', life: 3000,
        });
      }
      const smsgateways = await getSmsGateways();
      if (!Array.isArray(smsgateways)) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: smsgateways?.response?.data?.message ? smsgateways?.response?.data?.message : 'An unexpected error occurred getting SMS Gateway profiles', life: 3000,
        });
      }
      if (!Array.isArray(smtps) && !Array.isArray(smsgateways)) {
        setError(true);
      }
      setSendingProfiles(
        [...Array.isArray(smtps) ? smtps : [], ...Array.isArray(smsgateways) ? smsgateways : []],
      );
    }
    fetchData();
  }, []);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold">
        {campaignId ? `Edit ${campaign?.name ? campaign.name : ''} ` : 'Create a new'}
        {' '}
        campaign
      </span>
    </div>
  );
  return (
    <Dialog
      visible={visible}
      modal
      draggable={false}
      header={headerElement}
      onHide={() => setVisible(false)}
    >
      {error && (
        <InfoComponent title="An error occurred" message="Please try refreshing the page. If the error persists, please contact us" severity="error" />
      )}
      {sendingProfiles === null && !error && (
        <CustomSpinnerComponent text="campaign form" />
      )}
      {sendingProfiles?.length === 0 && !error
        && (
          <InfoComponent
            title="No sending profiles found"
            message={(
              <>
                You have to create one in order to be able to send a campaign. Let&apos;s create one
                {' '}
                <a href="/sending-profiles">here</a>
                !
              </>
            )}
            severity="info"
          />
        )}
      {sendingProfiles?.length > 0 && !error
        && (
          <ManageCampaignFormComponent
            campaign={campaign}
            sendingProfiles={sendingProfiles}
            setVisible={setVisible}
            mode={campaignId ? 'edit' : 'create'}
          />
        )}
    </Dialog>
  );
}

ManageCampaignModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  campaignId: PropTypes.number,
};

ManageCampaignModalComponent.defaultProps = {
  campaignId: null,
};

export default ManageCampaignModalComponent;
