import axiosHandler from './index';

const getBehaviorsReasons = () => {
    return axiosHandler.get('/behaviors_reasons/');
}

const getBehaviorReason = (id) => {
    return axiosHandler.get(`/behaviors_reasons/${id}`)
}

export { getBehaviorsReasons, getBehaviorReason };
