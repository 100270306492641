import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import withContent from '../withContent';
import { getUsersManagement } from '../../apis/usersManagement.ts';
import UserManagementListComponent from '../../components/UserManagementComponent/UserManagementListComponent/UserManagementListComponent';
import ManageUserManagementModalComponent from '../../components/UserManagementComponent/ManageUserManagementModalComponent/ManageUserManagementModalComponent';
import getUserInfoState from '../../redux/reducers/selectors/userInfo';
import { useToast } from '../../context/ToastContext';
import InfoComponent from '../../components/UtilsComponents/InfoComponent';
import CustomSpinnerComponent from '../../components/CustomSpinnerComponent/CustomSpinnerComponent';

function UserManagementPage() {
  const [usersManagement, setUsersManagement] = useState();
  const [visible, setVisible] = useState(false);
  const { role } = useSelector(getUserInfoState);
  const [error, setError] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const response = await getUsersManagement();
      if (!Array.isArray(response) || response.isAxiosError) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? response?.response?.data?.message : 'An unexpected error occurred getting users', life: 3000,
        });
        setError(true);
        setUsersManagement([]);
      } else {
        setUsersManagement(response);
      }
    }
    fetchData();
  }, []);

  if (role !== 'admin') {
    navigate('/');
  }

  return (
    <>
      <Button disabled={usersManagement?.length === 0} className="m-2" type="button" icon="pi pi-plus" onClick={() => setVisible(true)} label="New user" aria-controls={visible ? 'dlg' : null} aria-expanded={visible} />
      {usersManagement === undefined ? (
        <CustomSpinnerComponent text="users" />
      ) : (
        <>
          {error && (
          <InfoComponent
            title="An error occurred"
            message="Please try refreshing the page. If the error persists, please contact us."
            severity="error"
          />
          )}

          {!error && usersManagement?.length === 0 && (
          <InfoComponent
            title="No users"
            message="If you don&apos;t see any user, something went wrong."
            severity="info"
          />
          )}
          <ManageUserManagementModalComponent
            visible={visible}
            setVisible={setVisible}
            users={usersManagement}
          />
          <UserManagementListComponent usersManagement={usersManagement} />
        </>
      )}
    </>
  );
}

export default withContent(UserManagementPage);
